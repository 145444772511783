import React, { Component } from "react";
import { connect } from "react-redux";

import UserNotificationBar from "../notification/UserNotificationBar";
// import Modal from "../generic/Modal";
import { EwoloFormHintSplit } from "../generic/EwoloFormHint";

import logWeightActions from "../../modules/log-weight/logWeightActions";
import userWeightActions from "../../modules/user-weight/userWeightActions";

import { ewoloUtil } from "ewolo-core-js";

import "./LogWeight.css";
import WeightEntryView from "./WeightEntryView";

const mapStateToProps = (state) => {
  return {
    logWeight: state.user.logWeight,
    weights: state.user.userWeight.weights,
    defaultUnits: state.user.data.units,
    authToken: state.user.data.authToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchUserWeightEntriesThunk: () => {
      dispatch(userWeightActions.fetchUserWeightEntriesThunk());
    },
    doLogWeightSetData: ({ date, body, units }) => {
      dispatch(logWeightActions.logWeightSetData({ date, body, units }));
    },
    doLogWeightSaveThunk: () => {
      dispatch(logWeightActions.logWeightSaveThunk());
    },
    doDeleteWeightEntryThunk: (weight) => {
      dispatch(userWeightActions.userWeightDeleteThunk(weight));
    },
  };
};

class LogWeight extends Component {
  componentDidMount() {
    // since this route can be loaded from an unauthenticated source,
    // add guard
    if (this.props.authToken) {
      this.props.doFetchUserWeightEntriesThunk();
    }

    this.props.doLogWeightSetData({
      date: ewoloUtil.getTodaysDate(),
      body: 0,
      units: this.props.defaultUnits,
    });
  }

  handleDateChange = (event) => {
    this.props.formUpdated();

    this.props.doLogWeightSetData({
      date: event.target.value,
      body: this.props.logWeight.body,
      units: this.props.logWeight.units,
    });
  };

  handleBodyChange = (event) => {
    this.props.formUpdated();

    this.props.doLogWeightSetData({
      date: this.props.logWeight.date,
      body: event.target.value,
      units: this.props.logWeight.units,
    });
  };

  handleUnitsSelectionChange = (event) => {
    this.props.formUpdated();

    this.props.doLogWeightSetData({
      date: this.props.logWeight.date,
      body: this.props.logWeight.body,
      units: event.target.value,
    });
  };

  handleSave = (event) => {
    event.preventDefault();
    this.props.formSaved();
    this.props.doLogWeightSaveThunk();
  };

  isSaveDisabled = () => {
    if (this.props.logWeight.dateFormHint) {
      return true;
    }

    return false;
  };

  render() {
    const pageTitle = <h3>Log a new weight entry</h3>;

    return (
      <div>
        <UserNotificationBar />

        <div className="container grid-xs section-content">
          <div className="columns">
            <div className="column col-12">{pageTitle}</div>
          </div>

          <div className="columns">
            <div className="column col-12">
              <form className="form-horizontal">
                <div className="form-group">
                  <div className="col-3">
                    <label className="form-label">Date</label>
                  </div>
                  <div className="col-9">
                    <input
                      className="form-input"
                      type="date"
                      value={this.props.logWeight.date}
                      onChange={this.handleDateChange}
                    />
                  </div>
                </div>

                <EwoloFormHintSplit formHint={this.props.logWeight.dateFormHint} />

                <div className="form-group">
                  <div className="col-3">
                    <label className="form-label">Body weight</label>
                  </div>
                  <div className="col-4">
                    <input
                      className="form-input"
                      type="number"
                      step="0.1"
                      placeholder="0.0"
                      min="0"
                      max="1000"
                      property="body"
                      value={this.props.logWeight.body}
                      onChange={this.handleBodyChange}
                      onFocus={ewoloUtil.setCursorAtEndOnFocus}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-3">
                    <label className="form-label">Units</label>
                  </div>
                  <div className="col-9">
                    <select
                      className="form-select"
                      value={this.props.logWeight.units}
                      onChange={this.handleUnitsSelectionChange}
                    >
                      <option value="1">Pounds (lbs)</option>
                      <option value="2">Kilograms (kgs)</option>
                    </select>
                  </div>
                </div>

                {/* TODO: disable form when invalid data */}
                <div className="form-group form-group-action">
                  <div className="col-12 text-center">
                    <button
                      id="btn-save-workout"
                      className={"btn btn-primary btn-lg" + (this.isSaveDisabled() ? " disabled" : "")}
                      onClick={this.handleSave}
                    >
                      Save Weight
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {this.renderWeights(this.props.authToken, this.props.weights)}
      </div>
    );
  }

  renderWeights(authToken, weights) {
    if (!authToken) {
      return null;
    }

    return (
      <div className="container grid-xs section-content">
        <div className="columns margin-top-1rem">
          <div className="column col-12">
            <h3>Weights</h3>

            <div className="accordion width-100">{this.renderWeightEntries(weights)}</div>
          </div>
        </div>
      </div>
    );
  }

  renderWeightEntries(weights) {
    return weights.map((weight) => {
      return (
        <WeightEntryView
          key={weight.id}
          weight={weight}
          defaultUnits={this.props.defaultUnits}
          doDeleteWeightEntryThunk={this.props.doDeleteWeightEntryThunk}
        />
      );
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogWeight);
