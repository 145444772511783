import { combineReducers } from "redux";

import logWorkoutReducer from "./log-workout/logWorkoutReducer";
import userDataReducer from "./user-data/userDataReducer";
import userWorkoutsReducer from "./user-workouts/userWorkoutsReducer";
import analyticsReducer from "./analytics/analyticsReducer";
import logWeightReducer from "./log-weight/logWeightReducer";
import userWeightReducer from "./user-weight/userWeightReducer";
import logRunReducer from "./log-run/logRunReducer";
import userRunReducer from "./user-run/userRunReducer";

const userReducer = combineReducers({
  logWorkout: logWorkoutReducer,
  data: userDataReducer,
  workouts: userWorkoutsReducer,
  analytics: analyticsReducer,
  logWeight: logWeightReducer,
  userWeight: userWeightReducer,
  logRun: logRunReducer,
  userRun: userRunReducer,
});

export default userReducer;
