import { ewoloUtil } from "ewolo-core-js";
import { c } from "./logRunActions";

export const initialState = {
  datetime: "",
  meters: 0,
  seconds: 1,
  dateFormHint: "",
};

const logRunReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.LOG_RUN_SET_DATA: {
      const { datetime, meters, seconds } = action;
      const dateFormHint = action.datetime ? "" : "Required or invalid";

      return {
        ...state,
        datetime,
        meters,
        seconds,
        dateFormHint: dateFormHint,
      };
    }
    /*
    case c.LOG_WEIGHT_COPY:
      {
        const weight = action.weight;

        return {
          date: ewoloUtil.getTodaysDate(),
          body: weight.body,
          units: weight.units,
          dateFormHint: ''
        };
      }
    case c.LOG_WEIGHT_EDIT:
      {
        const weight = action.weight;

        return {
          id: weight.id,
          date: weight.date,
          body: weight.body,
          units: weight.units,
          dateFormHint: ''
        }
      }
    */
    case c.LOG_RUN_SAVE_SUCCESS: {
      return {
        datetime: ewoloUtil.getTodaysDate(),
        meters: 0,
        seconds: 0,
        dateFormHint: "",
      };
    }

    default:
      return state;
  }
};

export default logRunReducer;
