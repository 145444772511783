import React from "react";

import { ewoloUtil } from "ewolo-core-js";
import calculateSuperSetIndexes from "../../services/superSetService";

import "./WorkoutViewExercise.css";

function WorkoutViewExercises({ exercises, defaultUnits, showUnits = false }) {
  const exercisesWithSuperSetIndexes = calculateSuperSetIndexes(exercises);

  const exercisesOrdered = orderExercises(exercisesWithSuperSetIndexes);

  return (
    <div className="columns workout-details">{renderExercises({ exercisesOrdered, defaultUnits, showUnits })}</div>
  );
}

const renderExercises = function ({ exercisesOrdered, defaultUnits, showUnits }) {
  if (exercisesOrdered.length === 0) {
    return (
      <div className="column col-12 row">
        <i>No exercises logged</i>
      </div>
    );
  }

  return exercisesOrdered.map((exercise, index) => {
    if (exercise.setHeader) {
      return (
        <div
          key={index + "-" + exercise.name}
          className={"column col-12 workout-exercise " + (exercise.isSuperSet ? "row-super-set" : "row")}
        >
          {exercise.isSuperSet ? "+" : ""}
          {exercise.name}
        </div>
      );
    }

    const showTempo = exercise.tempo !== "101";
    const showRest = exercise.rest !== 60;
    const showDivider = showTempo && showRest;

    return (
      <div className="column col-12 row" key={exercise.id}>
        <div className="columns">
          <div className="column col-6 text-right row">
            {exercise.reps}{" "}
            {exercise.weight > 0
              ? "x " +
                getExerciseWeightValueString(
                  ewoloUtil.unitsToText,
                  defaultUnits,
                  showUnits,
                  exercise.units,
                  exercise.weight
                )
              : ""}
          </div>
          <div className="column col-6 row">
            {showTempo ? exercise.tempo : ""} {showDivider ? " / " : ""}
            {showRest ? exercise.rest : ""}
          </div>
        </div>
        {exercise.exerciseNotes ? (
          <div className="columns">
            <div className="column col-12">
              <div className="exercise-notes">{exercise.exerciseNotes}</div>
            </div>
          </div>
        ) : null}
      </div>
    );
  });
};

const orderExercises = function (exercises) {
  const result = [];

  exercises.sort((a, b) => {
    return a.workoutOrder - b.workoutOrder;
  });

  let previous = {
    name: null,
  };

  exercises.forEach((exercise, i) => {
    if (previous.name !== exercise.name) {
      previous = exercise;

      result.push({
        name: previous.name,
        setHeader: true,
        isSuperSet: previous.superSetIndex > 1 ? true : false,
      });
    }
    result.push(exercise);
  });

  return result;
};

const getExerciseWeightValueString = function (unitsToTextFn, defaultUnits, showUnits, units, weight) {
  return weight + (units !== defaultUnits || showUnits ? " " + unitsToTextFn(units) : "");
};

export default WorkoutViewExercises;
