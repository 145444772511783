import React, { Component } from "react";
import { connect } from "react-redux";

import "./Publik.css";

import UserNotificationBar from "../notification/UserNotificationBar";
import WorkoutPieChart from "../charts/WorkoutPieChart";

import publikActions from "../../modules/publik/publikActions";
import WorkoutViewExercises from "../dashboard/WorkoutViewExercises";

const mapStateToProps = (state /*, ownProps*/) => {
  return { links: state.publik.links, authToken: state.user.data.authToken, defaultUnits: state.user.data.units };
};

const mapDispatchToProps = {
  doPublikLinkFetchDataThunk: publikActions.linkFetchDataThunk,
};

class Publik extends Component {
  getLinkId() {
    return this.props.match.params.linkId;
  }

  componentDidMount() {
    const linkId = this.getLinkId();
    this.props.doPublikLinkFetchDataThunk(linkId);
  }

  render() {
    // console.log('rendering Publik');

    const renderedChart = this.renderChart();
    const renderedCallToAction = this.renderCallToAction();

    return (
      <div>
        <UserNotificationBar /> {renderedChart}
        <div className="container grid-md section-content">
          <div className="columns margin-top-2rem">
            <div className="column col-12 text-center">{renderedCallToAction}</div>
          </div>
        </div>
      </div>
    );
  }

  renderChart() {
    const linkId = this.getLinkId();
    const data = this.props.links[linkId];

    if (!data) {
      return null;
    }

    // console.log(Chartist.getDataArray(data.workoutBreakdown, false));

    return (
      <div>
        <div className="container grid-md section-content margin-bottom-1rem">
          <div className="columns">
            <div className="column col-12">
              {data.userName} logged a workout for {data.date}
            </div>
            {data.workout.notes && <div className="column col-12 publik-workout-notes">{data.workout.notes}</div>}
          </div>
        </div>
        <div className="container grid-xl margin-bottom-2rem">
          <div className="columns">
            <div className="column col-12">
              <WorkoutPieChart data={data.workoutBreakdown} />
            </div>
          </div>
        </div>
        <div className="container grid-sm p-centered margin-bottom-1rem">
          <WorkoutViewExercises
            defaultUnits={this.props.defaultUnits}
            exercises={data.workout.exercises}
            showUnits={true}
          />
        </div>

        <div className="container grid-sm p-centered">
          <p className="no-text">Tempo (default 101) and Rest (default 60) are only displayed if not default values.</p>
        </div>
      </div>
    );
  }

  renderCallToAction() {
    if (this.props.authToken) {
      return (
        <button className="btn btn-primary btn-lg" onClick={this.handleBtnLogWorkoutClick}>
          Log a workout now!
        </button>
      );
    }

    return (
      <div>
        <p className="no-text">Want your own progress chart?</p>
        <div>
          <button className="btn btn-primary btn-lg" onClick={this.handleBtnSignupClick}>
            Create a free account
          </button>
        </div>
        <div className="margin-top-1rem">
          <p className="no-text">and start logging your workouts now!</p>
        </div>
      </div>
    );
  }

  handleBtnLogWorkoutClick = (event) => {
    event.preventDefault();
    this.props.history.push("/log-workout");
  };

  handleBtnSignupClick = (event) => {
    event.preventDefault();
    this.props.history.push("/signup");
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Publik);
