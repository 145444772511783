import moment from "moment";
import { ewoloUtil, ewoloConstants } from "ewolo-core-js";
import { handleError } from "../../common/errorHandler";

import globalActions from "../global/globalActions";

export const c = {
  USER_WORKOUTS_FETCH_SUCCESS: "USER-WORKOUTS-FETCH-SUCCESS",
  USER_WORKOUTS_DELETE_SUCCESS: "USER-WORKOUTS-DELETE-SUCCESS",
  USER_WORKOUTS_SET_VIEW_DETAILS: "USER-WORKOUTS-SET-VIEW-DETAILS",
  USER_WORKOUTS_ANALYSIS_FETCH_SUCCESS: "USER-WORKOUTS-ANALYSIS-FETCH-SUCCESS",
  USER_WORKOUTS_MARK_DIRTY: "USER-WORKOUTS-MARK-DIRTY",
  USER_WORKOUTS_ANALYSIS_MARK_DIRTY: "USER-WORKOUTS-ANALYSIS-MARK-DIRTY",
};

const userWorkoutsActions = {
  userWorkoutsAnalysisFetchSuccess: (workoutsAnalysis) => {
    return {
      type: c.USER_WORKOUTS_ANALYSIS_FETCH_SUCCESS,
      workoutsAnalysis: workoutsAnalysis,
    };
  },
  fetchUserWorkoutsAnalysisThunk: (dateBefore, dateAfter, exerciseName) => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;
      const userId = getState().user.data.id;
      const units = getState().user.data.units;

      if (!authToken) {
        return Promise.resolve().then(() => {
          dispatch(
            globalActions.userNotificationAdd({
              type: "ERROR",
              text: "Cannot fetch workout progress data because user is not logged in.",
            })
          );
        });
      }

      dispatch(globalActions.taskStart());

      const promise = ewoloUtil.getApiRequest({
        route:
          `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_ANALYSIS_WORKOUTS}?units=${units}` +
          (dateBefore ? "&dateBefore=" + dateBefore : "") +
          (dateAfter ? "&dateAfter=" + dateAfter : "") +
          (exerciseName ? "&exerciseName=" + exerciseName : ""),
        method: "GET",
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then((body) => {
          dispatch(userWorkoutsActions.userWorkoutsAnalysisFetchSuccess(body));
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage: "An error occured when loading user workouts progress data.",
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },
  userWorkoutsFetchSuccess: (workouts) => {
    return {
      type: c.USER_WORKOUTS_FETCH_SUCCESS,
      workouts: workouts,
    };
  },
  fetchUserWorkoutsThunk: (dateBefore) => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;
      const userId = getState().user.data.id;

      // we fetch workouts for one day before the day specified to handle any rollover error
      let queryDateBefore = null;
      if (dateBefore) {
        queryDateBefore = moment(dateBefore).subtract(1, "days").format("YYYY-MM-DD");
      }

      const route =
        `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_WORKOUTS}` +
        (queryDateBefore ? "?dateBefore=" + queryDateBefore : "");

      dispatch(globalActions.taskStart());

      const promise = ewoloUtil.getApiRequest({
        route: route,
        method: "GET",
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then((body) => {
          dispatch(userWorkoutsActions.userWorkoutsFetchSuccess(body));
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage: "An error occured when loading user workouts",
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },
  fetchUserWorkoutThunk: (userId, workoutId) => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;

      dispatch(globalActions.taskStart());

      const promise = ewoloUtil.getApiRequest({
        route: `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_WORKOUTS}/${workoutId}`,
        method: "GET",
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then((body) => {
          // TODO: fix this to allow loading more workouts still!
          dispatch(userWorkoutsActions.userWorkoutsFetchSuccess([body]));
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage: "An error occured when loading user workout",
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },
  userWorkoutsDeleteSuccess: (workoutId) => {
    return {
      type: c.USER_WORKOUTS_DELETE_SUCCESS,
      workoutId: workoutId,
    };
  },
  deleteUserWorkoutThunk: (workoutId, workoutDate) => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;
      const userId = getState().user.data.id;

      dispatch(globalActions.taskStart());

      const promise = ewoloUtil.getApiRequest({
        route: `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_WORKOUTS}/${workoutId}`,
        method: "DELETE",
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponseStatus)
        .then((status) => {
          dispatch(userWorkoutsActions.userWorkoutsAnalysisMarkDirty(true));
          dispatch(userWorkoutsActions.userWorkoutsDeleteSuccess(workoutId));
          dispatch(
            globalActions.userNotificationAdd({
              type: "SUCCESS",
              text: `Deleted workout for ${workoutDate}`,
            })
          );
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage: "An error occured when deleting a workout",
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },
  userWorkoutsSetViewDetails: (workoutId, show) => {
    return {
      type: c.USER_WORKOUTS_SET_VIEW_DETAILS,
      workoutId: workoutId,
      show: show,
    };
  },
  userWorkoutsMarkDirty: (isDirty) => {
    return {
      type: c.USER_WORKOUTS_MARK_DIRTY,
      isDirty,
    };
  },
  userWorkoutsAnalysisMarkDirty: (isDirty) => {
    return {
      type: c.USER_WORKOUTS_ANALYSIS_MARK_DIRTY,
      isDirty,
    };
  },
};

export default userWorkoutsActions;
