import moment from "moment";

export const getAnalyticsExerciseChartistSeriesData = (apiResponse) => {
  const result = [];

  for (const series of apiResponse) {
    const seriesData = [];
    for (const dataPoint of series.data) {
      seriesData.push({
        x: moment(dataPoint.date).toDate(),
        y: dataPoint.weight,
      });
    }

    result.push({
      name: series.name,
      data: seriesData,
      className: "blabla",
    });
  }

  // sort series data from lowest to highest
  result.sort((a, b) => {
    const rmA = parseInt(a.name.replace("RM", ""), 10);
    const rmB = parseInt(b.name.replace("RM", ""), 10);
    return rmA - rmB;
  });

  return result;
};

export const getExerciseName = (exerciseFilterData, userExerciseNames) => {
  if (exerciseFilterData.exerciseName) {
    return exerciseFilterData.exerciseName;
  }

  if (userExerciseNames.length) {
    // try to pick squats
    for (let i = 0; i < userExerciseNames.length; ++i) {
      if ("squats" === userExerciseNames[i].trim().toLowerCase()) {
        return userExerciseNames[i];
      }
    }

    return userExerciseNames[0];
  }

  return "";
};

export const getDateRangeSelectionValues = ({ dateRange, dateBefore, dateAfter }) => {
  const result = {
    dateRange,
    dateBefore: dateBefore ? dateBefore : moment().add(1, "days").format("YYYY-MM-DD"),
    dateAfter,
  };

  switch (dateRange) {
    case "last-week": {
      result.dateAfter = moment().subtract(8, "days").format("YYYY-MM-DD");
      break;
    }
    case "last-month": {
      result.dateAfter = moment().subtract(1, "months").subtract(1, "days").format("YYYY-MM-DD");
      break;
    }
    case "last-year": {
      result.dateAfter = moment().subtract(1, "years").subtract(1, "days").format("YYYY-MM-DD");
      break;
    }
    default: {
      // do nothing
    }
  }

  return result;
};
