import moment from "moment";
import * as entityService from "../../services/entityService";
import * as workoutsService from "../../services/workoutsService";

import { c } from "./userWorkoutsActions";

export const initialState = {
  workouts: {},
  dirtyWorkouts: true,
  workoutsViewDetails: {},
  workoutsAnalysis: [],
  dirtyWorkoutsAnalysis: true,
  lastWorkoutDate: moment().format("YYYY-MM-DD"),
  displayMoreWorkouts: true,
};

const userWorkoutsReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.USER_WORKOUTS_FETCH_SUCCESS: {
      const { workouts } = action;

      const normalized = entityService.normalize(workouts);
      const merged = {
        ...state.workouts,
        ...normalized,
      };
      const lastWorkoutDate = workoutsService.getLastDate(merged);

      return {
        ...state,
        lastWorkoutDate,
        workouts: merged,
        dirtyWorkouts: false,
        displayMoreWorkouts: workouts.length >= 20,
      };
    }
    case c.USER_WORKOUTS_ANALYSIS_FETCH_SUCCESS: {
      const { workoutsAnalysis } = action;

      return {
        ...state,
        workoutsAnalysis: workoutsAnalysis,
        dirtyWorkoutsAnalysis: false,
      };
    }
    case c.USER_WORKOUTS_DELETE_SUCCESS: {
      const { workoutId } = action;

      const workouts = {
        ...state.workouts,
      };

      delete workouts[workoutId + ""];

      return {
        ...state,
        workouts: workouts,
      };
    }
    case c.USER_WORKOUTS_SET_VIEW_DETAILS: {
      const { workoutId, show } = action;
      const workoutsViewDetails = {
        ...state.workoutsViewDetails,
      };
      workoutsViewDetails[workoutId] = show;

      return {
        ...state,
        workoutsViewDetails: workoutsViewDetails,
      };
    }
    case c.USER_WORKOUTS_ANALYSIS_MARK_DIRTY: {
      const { isDirty } = action;
      return {
        ...state,
        dirtyWorkoutsAnalysis: isDirty,
      };
    }
    case c.USER_WORKOUTS_MARK_DIRTY: {
      const { isDirty } = action;
      return {
        ...state,
        dirtyWorkouts: isDirty,
      };
    }

    default:
      return state;
  }
};

export default userWorkoutsReducer;
