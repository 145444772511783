import { push } from "connected-react-router";
import { ewoloUtil, ewoloConstants } from "ewolo-core-js";

import { handleError } from "../../common/errorHandler";

import globalActions from "../global/globalActions";
import signupActions from "../signup/signupActions";
// import userDataActions, { fetchUserDataThunkPromise } from '../user-data/userDataActions';
import userWorkoutActions from "../user-workouts/userWorkoutsActions";

export const c = {
  LOG_WEIGHT_SET_DATA: "LOG-WEIGHT-SET-DATA",
  LOG_WEIGHT_SAVE_SUCCESS: "LOG-WEIGHT-SAVE-SUCCESS",
  // LOG_WEIGHT_COPY: 'LOG-WEIGHT-COPY',
  // LOG_WEIGHT_EDIT: 'LOG-WEIGHT-EDIT',
  WEIGHT_DELETE_SUCCESS: "WEIGHT-DELETE-SUCCESS",
};

const logWeightActions = {
  logWeightSetData: ({ date = ewoloUtil.getTodaysDate(), body, units }) => {
    return {
      type: c.LOG_WEIGHT_SET_DATA,
      date,
      body,
      units,
    };
  },
  logWeightSaveSuccess: (id) => {
    return {
      type: c.LOG_WEIGHT_SAVE_SUCCESS,
      id: id,
    };
  },
  logWeightSaveThunk: () => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;

      if (!authToken) {
        return Promise.resolve().then(() => {
          const action = logWeightActions.logWeightSaveThunk();
          dispatch(signupActions.signupSetAfterSuccess(action));
          dispatch(push(ewoloConstants.api.routes.ROUTE_SIGNUP));
        });
      }

      const logWeight = getState().user.logWeight;
      const userId = getState().user.data.id;
      const logWeightDate = logWeight.date;
      const logWeightId = logWeight.id;

      dispatch(globalActions.taskStart());

      let promise = null;

      if (logWeightId) {
        promise = ewoloUtil.getApiRequest({
          route: `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_WEIGHT}/${logWeightId}`,
          method: "PUT",
          body: logWeight,
          authToken: authToken,
        });
      } else {
        promise = ewoloUtil.getApiRequest({
          route: `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_WEIGHT}`,
          method: "POST",
          body: logWeight,
          authToken: authToken,
        });
      }

      return promise
        .then(ewoloUtil.getApiResponse)
        .then((body) => {
          const weightId = body.id;
          dispatch(userWorkoutActions.userWorkoutsAnalysisMarkDirty(true));
          dispatch(logWeightActions.logWeightSaveSuccess(weightId));
          dispatch(
            globalActions.userNotificationAdd({
              type: "SUCCESS",
              text: "Saved weight for " + logWeightDate,
            })
          );
          dispatch(push("/"));
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage:
              "An error occured when saving weight for " +
              logWeightDate +
              ". Please click the back button and try again.",
            codeMessages: {
              409: `An entry with the date ${logWeightDate} already exists. Please delete the existing one and retry.`,
            },
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },

  /*
  logWeightCopy: (weight) => {
    return {
      type: c.LOG_WEIGHT_COPY,
      weight
    };
  },
  logWeightCopyThunk: (weight) => {
    return (dispatch, getState) => {
      return Promise.resolve()
        .then(() => {
          dispatch(logWeightActions.logWeightCopy(weight));
          dispatch(push('/log-weight'));
        });
    };
  },
  */
};

export default logWeightActions;
