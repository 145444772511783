import { push } from "connected-react-router";
import { ewoloUtil, ewoloConstants } from "ewolo-core-js";

import { handleError } from "../../common/errorHandler";

import globalActions from "../global/globalActions";
import signupActions from "../signup/signupActions";
// import userDataActions, { fetchUserDataThunkPromise } from '../user-data/userDataActions';
import userWorkoutActions from "../user-workouts/userWorkoutsActions";

export const c = {
  LOG_RUN_SET_DATA: "LOG-RUN-SET-DATA",
  LOG_RUN_SAVE_SUCCESS: "LOG-RUN-SAVE-SUCCESS",
  RUN_DELETE_SUCCESS: "RUN-DELETE-SUCCESS",
};

const logRunActions = {
  logRunSetData: ({ datetime = ewoloUtil.getTodaysDate(), meters = 1000, seconds = 300 }) => {
    return {
      type: c.LOG_RUN_SET_DATA,
      datetime,
      meters,
      seconds,
    };
  },
  logRunSaveSuccess: (id) => {
    return {
      type: c.LOG_RUN_SAVE_SUCCESS,
      id: id,
    };
  },
  logRunSaveThunk: () => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;

      if (!authToken) {
        return Promise.resolve().then(() => {
          const action = logRunActions.logRunSaveThunk();
          dispatch(signupActions.signupSetAfterSuccess(action));
          dispatch(push(ewoloConstants.api.routes.ROUTE_SIGNUP));
        });
      }

      const logRun = getState().user.logRun;
      const userId = getState().user.data.id;
      const logRunDatetime = logRun.datetime;
      const logRunId = logRun.id;

      dispatch(globalActions.taskStart());

      let promise = null;

      if (logRunId) {
        promise = ewoloUtil.getApiRequest({
          route: `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_RUNNING}/${logRunId}`,
          method: "PUT",
          body: logRun,
          authToken: authToken,
        });
      } else {
        promise = ewoloUtil.getApiRequest({
          route: `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_RUNNING}`,
          method: "POST",
          body: logRun,
          authToken: authToken,
        });
      }

      return promise
        .then(ewoloUtil.getApiResponse)
        .then((body) => {
          const runId = body.id;
          dispatch(userWorkoutActions.userWorkoutsAnalysisMarkDirty(true));
          dispatch(logRunActions.logRunSaveSuccess(runId));
          dispatch(
            globalActions.userNotificationAdd({
              type: "SUCCESS",
              text: "Saved run for " + logRunDatetime,
            })
          );
          // dispatch(push("/"));
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage:
              "An error occured when saving run for " +
              logRunDatetime +
              ". Please click the back button and try again.",
            codeMessages: {
              409: `An run with the date ${logRunDatetime} already exists. Please delete the existing one and retry.`,
            },
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },

  /*
  logWeightCopy: (weight) => {
    return {
      type: c.LOG_WEIGHT_COPY,
      weight
    };
  },
  logWeightCopyThunk: (weight) => {
    return (dispatch, getState) => {
      return Promise.resolve()
        .then(() => {
          dispatch(logWeightActions.logWeightCopy(weight));
          dispatch(push('/log-weight'));
        });
    };
  },
  */
};

export default logRunActions;
