import React from "react";
import { Link } from "react-router-dom";

import BlogTemplate from "./BlogTemplate";

import ewoloLogWeightEntry from "./media/ewolo-log-weight-entry.png";
import ewoloVictorLastYearsData from "./media/ewolo-victor-last-years-data.png";

const entry = {
  title: "Ewolo version 4 - Body weight tracking",
  url: "/blog/ewolo-version-4-body-weight-tracking",
  author: "Victor Parmar",
  dateStr: "January 28, 2018",
};

const Version4BodyWeightTracking = (props) => {
  const ewoloLogWeightEntryText = "Log your body weight using ewolo and compare it with your training progress!";
  const ewoloVictorLastYearsDataText = "Victor's progress chart for the past year!";

  return (
    <BlogTemplate entry={entry}>
      <p>
        Ewolo version 4 marks a much awaited feature - <Link to="/log-weight">body weight tracking</Link>! You can now
        log your body weight in ewolo and most importantly, the dashboard analysis chart includes body weight
        measurements so that you can effectively evaluate your training progress :)
      </p>

      <figure className="figure blog">
        <a href={ewoloLogWeightEntry}>
          <img src={ewoloLogWeightEntry} className="img-responsive bordered p-centered" alt={ewoloLogWeightEntryText} />
        </a>
        <figcaption className="figure-caption text-center">{ewoloLogWeightEntryText}</figcaption>
      </figure>

      <p>
        Note that to better visualize the dashboard analysis,{" "}
        <u>
          <em>the workout volume and the body weight is normalized to a value between 0 and 100</em>
        </u>
        . Also, body weight logging is independent of logging workouts. While logging both weight and workouts work well
        together, you can use Ewolo to simply track just your body weight if that is what you prefer.
      </p>

      <figure className="figure blog">
        <a href={ewoloVictorLastYearsData}>
          <img
            src={ewoloVictorLastYearsData}
            className="img-responsive bordered p-centered"
            alt={ewoloVictorLastYearsDataText}
          />
        </a>
        <figcaption className="figure-caption text-center">{ewoloVictorLastYearsDataText}</figcaption>
      </figure>

      <p>As usual, apart from body weight tracking, there have been a few UX improvements:</p>
      <ul className="text">
        <li>When logging a new exercise, the cursor is set to the end of the input field.</li>
        <li>A confirmation dialog pops up when navigating away from logging a workout with unsaved changes.</li>
        <li>The save button is disabled if there are any form errors.</li>
      </ul>

      <p>I hope that you enjoy the new features. All feedback is welcome!</p>
    </BlogTemplate>
  );
};

const Version4BodyWeightTrackingEntry = {
  ...entry,
  component: Version4BodyWeightTracking,
};

export default Version4BodyWeightTrackingEntry;
