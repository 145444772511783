import React, { Component } from "react";
import {
  /*BrowserRouter,*/
  Route,
  Switch,
} from "react-router-dom";

import { Provider } from "react-redux";

import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory as createHistory } from "history";

import packageJson from "./package.json.link"; // Needed to create a symlink because of https://github.com/facebookincubator/create-react-app/issues/2466
import lastUpdated from "./last-updated.json.link";

import { ewoloUtil, ewoloConstants } from "ewolo-core-js";

import Home from "./components/page/Home";
import Header from "./components/page/Header";
import AppNotification from "./components/notification/AppNotification";
import Footer from "./components/page/Footer";
import Privacy from "./components/page/Privacy";
import Terms from "./components/page/Terms";
import WhyEwolo from "./components/page/WhyEwolo";
// import LogWorkoutNew from './components/log-workout/LogWorkoutNew';
// import LogWorkoutEdit from './components/log-workout/LogWorkoutEdit';
// import LogWeight from './components/log-weight/LogWeight';
import Loader from "./components/page/Loader";
import Signup from "./components/auth/Signup";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import Dashboard from "./components/dashboard/Dashboard";
import Account from "./components/account/Account";
import WorkoutAnalytics from "./components/analytics/WorkoutAnalytics";
import Publik from "./components/publik/Publik";
import LogWeight from "./components/log-weight/LogWeight";
import LogWorkoutEdit from "./components/log-workout/LogWorkoutEdit";
import LogWorkoutNew from "./components/log-workout/LogWorkoutNew";
import LogRun from "./components/log-run/LogRun";

import Blog, { blogEntries } from "./components/blog/Blog";

import appReducer from "./modules/appReducer";
import { initialState as userDataReducerInitialState } from "./modules/user-data/userDataReducer";
import createEwoloStore from "./redux/createEwoloStore";

import * as appNotificationService from "./services/appNotificationService";

import globalActions from "./modules/global/globalActions";
import userDataActions from "./modules/user-data/userDataActions";

import "./App.css";
import "./notifications.css";

import "noty/lib/noty.css";
import "noty/lib/themes/semanticui.css";
import "./noty-spectre.css";

import withFormNavigationPrompt from "./components/generic/withFormNavigationPrompt";

const LogWeightWithFormNavigationPrompt = withFormNavigationPrompt(LogWeight);
const LogWorkoutEditWithFormNavigationPrompt = withFormNavigationPrompt(LogWorkoutEdit);
const LogWorkoutNewWithFormNavigationPrompt = withFormNavigationPrompt(LogWorkoutNew);
const LogRunWithFormNavigationPrompt = withFormNavigationPrompt(LogRun);

const history = createHistory();

const store = createEwoloStore(history, appReducer, []);

// initialize authToken should really be the single source of truth this needs
// to be here so that the redirects on auth can work!
const authToken = ewoloUtil.getObject(ewoloConstants.storage.authTokenKey);
const id = ewoloUtil.getObject(ewoloConstants.storage.userIdKey);
if (authToken && id) {
  const savedUserData = ewoloUtil.getObject(ewoloConstants.storage.userDataKey);
  console.log("savedUserData", savedUserData);
  if (savedUserData) {
    userDataReducerInitialState.name = savedUserData.name;
    userDataReducerInitialState.email = savedUserData.email;
    userDataReducerInitialState.userExerciseNames = savedUserData.userExerciseNames;
    userDataReducerInitialState.sex = savedUserData.sex;
    userDataReducerInitialState.units = savedUserData.units;
  }

  // only dispatch action after setting initial state
  store.dispatch(userDataActions.userAuthSuccess(authToken, id));
}

console.log("lastUpdated", lastUpdated);

class App extends Component {
  componentDidMount() {
    const appNotification = appNotificationService.getAppNotification();
    if (appNotification) {
      const appNotificationSeen = ewoloUtil.getObject(appNotification.id);

      if (appNotificationSeen === null) {
        // undefined means localStorage is disabled
        store.dispatch(
          globalActions.appNotificationSet(appNotification.id, appNotification.text, appNotification.show)
        );
      }
    }

    store.dispatch(userDataActions.fetchUserDataThunk());
  }

  render() {
    return (
      <React.StrictMode>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <div>
              <Loader />
              <AppNotification />
              <Header />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/why-ewolo" component={WhyEwolo} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/log-weight" component={LogWeightWithFormNavigationPrompt} />
                <Route exact path="/log-run" component={LogRunWithFormNavigationPrompt} />
                <Route exact path="/log-workout" component={LogWorkoutNewWithFormNavigationPrompt} />
                <Route path="/edit-workout/:workoutId" component={LogWorkoutEditWithFormNavigationPrompt} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/dashboard" component={Dashboard} /> {/* legacy support, should be dropped in 3.0 */}
                <Route exact path="/account" component={Account} />
                <Route exact path="/analytics" component={WorkoutAnalytics} />
                <Route path="/public/:linkId" component={Publik} />
                <Route exact path="/blog" component={Blog} />{" "}
                {blogEntries.map((blogEntry, index) => {
                  return <Route key={blogEntry.url} exact path={blogEntry.url} component={blogEntry.component} />;
                })}
                {/* default */}
                <Route component={Home} />
              </Switch>
              <Footer
                lastUpdated={lastUpdated.lastUpdated}
                clientVersion={packageJson.version}
                apiVersion={ewoloConstants.api.version}
              />
            </div>
          </ConnectedRouter>
        </Provider>
      </React.StrictMode>
    );
  }
}

/*
history.listen((location) => {
  console.log('location', location); // TODO: Think about clearing the message bar when transitioning to certain states
  // TODO: save to localstorage on transition
});
*/

export default App;
