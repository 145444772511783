import React, { Component } from "react";
import { connect } from "react-redux";

import { ewoloUtil } from "ewolo-core-js";

import logWorkoutActions from "../../modules/log-workout/logWorkoutActions";
import userWorkoutsActions from "../../modules/user-workouts/userWorkoutsActions";

import LogWorkout from "./LogWorkout";

const mapStateToProps = (state) => {
  return { workouts: state.user.workouts.workouts, userData: state.user.data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doLogWorkoutEdit: (workout) => {
      dispatch(logWorkoutActions.logWorkoutEdit(workout));
    },
    doFetchUserWorkout: (userId, workoutId) => {
      dispatch(userWorkoutsActions.fetchUserWorkoutThunk(userId, workoutId));
    },
  };
};

class LogWorkoutEdit extends Component {
  getWorkoutId() {
    return this.props.match.params.workoutId;
  }

  componentDidMount() {
    const workoutId = this.getWorkoutId();
    const workout = this.props.workouts[workoutId];
    if (workout) {
      this.props.doLogWorkoutEdit(workout);
    } else {
      this.props.doFetchUserWorkout(this.props.userData.id, this.props.match.params.workoutId);
    }
  }

  /*
  componentWillReceiveProps(nextProps) {
    const workoutId = this.getWorkoutId();
    const workout = this.props.workouts[workoutId];

    // only do logWorkoutEdit if you aren't already editing a workout
    if (!workout) {
      const loadedWorkout = nextProps.workouts[workoutId];
      if (loadedWorkout) {
        this.props.doLogWorkoutEdit(loadedWorkout);
      }
    }
  }
  */

  /**
   * If the edit page was loaded directly then the workout is set to be fetched
   * In this scenario we edit the workout that was then fetched, i.e. prevWorkout === undefined
   *
   * @param {*} prevProps
   * @param {*} prevState
   * @param {*} snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const workoutId = this.getWorkoutId();
    const workout = this.props.workouts[workoutId];

    const prevWorkoutId = prevProps.match.params.workoutId;
    const prevWorkout = prevProps.workouts[prevWorkoutId];

    // console.log(workoutId, prevWorkoutId, JSON.stringify(workout), JSON.stringify(prevWorkout));

    if (workoutId === prevWorkoutId && workout && prevWorkout === undefined) {
      this.props.doLogWorkoutEdit(workout);
    }
  }

  render() {
    return (
      <LogWorkout
        formSaved={this.props.formSaved ? this.props.formSaved : ewoloUtil.noOp}
        formUpdated={this.props.formUpdated ? this.props.formUpdated : ewoloUtil.noOp}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogWorkoutEdit);
