import { ewoloUtil } from "ewolo-core-js";
import { c } from "./logWeightActions";

export const initialState = {
  date: "",
  body: 0,
  units: 1,
  dateFormHint: "",
};

const logWeightReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.LOG_WEIGHT_SET_DATA: {
      const { date, body, units } = action;
      const dateFormHint = action.date ? "" : "Required or invalid";

      return {
        ...state,
        date,
        body,
        units,
        dateFormHint: dateFormHint,
      };
    }
    /*
    case c.LOG_WEIGHT_COPY:
      {
        const weight = action.weight;

        return {
          date: ewoloUtil.getTodaysDate(),
          body: weight.body,
          units: weight.units,
          dateFormHint: ''
        };
      }
    case c.LOG_WEIGHT_EDIT:
      {
        const weight = action.weight;

        return {
          id: weight.id,
          date: weight.date,
          body: weight.body,
          units: weight.units,
          dateFormHint: ''
        }
      }
    */
    case c.LOG_WEIGHT_SAVE_SUCCESS: {
      return {
        date: ewoloUtil.getTodaysDate(),
        body: 0,
        units: state.units,
        dateFormHint: "",
      };
    }

    default:
      return state;
  }
};

export default logWeightReducer;
