import { push } from "connected-react-router";
import { ewoloUtil, ewoloConstants } from "ewolo-core-js";
import { handleError } from "../../common/errorHandler";

import globalActions from "../global/globalActions";
import userDataActions from "../user-data/userDataActions"; // eslint-disable-line no-unused-vars
import userWorkoutsActions from "../user-workouts/userWorkoutsActions";

export const c = {
  USER_RUNS_FETCH_SUCCESS: "USER-RUNS-FETCH-SUCCESS",
  USER_RUN_DELETE_SUCCESS: "USER-RUN-DELETE-SUCCESS",
};

const userRunActions = {
  userRunsFetchSuccess: (runs) => {
    return {
      type: c.USER_RUNS_FETCH_SUCCESS,
      runs,
    };
  },
  fetchUserRunsThunk: () => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;
      const userId = getState().user.data.id;
      const route = `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_RUNNING}`;

      dispatch(globalActions.taskStart());

      const promise = ewoloUtil.getApiRequest({
        route: route,
        method: "GET",
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then((body) => {
          dispatch(userRunActions.userRunsFetchSuccess(body));
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage: "An error occured when loading runs",
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },

  userRunDeleteSuccess: (run) => {
    return {
      type: c.USER_RUN_DELETE_SUCCESS,
      run,
    };
  },
  userRunDeleteThunk: (run) => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;
      const userId = getState().user.data.id;
      const runDatetime = run.datetime;
      const runId = run.id;

      dispatch(globalActions.taskStart());

      let promise = ewoloUtil.getApiRequest({
        route: `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_RUNNING}/${runId}`,
        method: "DELETE",
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then((body) => {
          dispatch(userWorkoutsActions.userWorkoutsAnalysisMarkDirty(true));
          dispatch(userRunActions.userRunDeleteSuccess(run));
          dispatch(
            globalActions.userNotificationAdd({
              type: "SUCCESS",
              text: "Deleted run for " + runDatetime,
            })
          );
          dispatch(push(`${ewoloConstants.api.routes.ROUTE_LOG_RUN}`));
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage:
              "An error occured when deleting run for " + runDatetime + ". Please wait a moment and try again.",
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },
};

export default userRunActions;
