import React from "react";

const NoActivityPanel = (props) => {
  /*
  const handleBtnActionClick = event => {
    event.preventDefault();
    props.history.push("/log-workout");
  };
  */

  const renderActionButton = () => {
    if (props.handleBtnActionClick && props.btnActionText) {
      return (
        <button className="btn btn-primary" onClick={props.handleBtnActionClick}>
          {props.btnActionText}
        </button>
      );
    }

    return null;
  };

  return (
    <div className="column col-12">
      <div className="empty width-100">
        <div className="empty-icon">
          <i className="icon icon-flag" />
        </div>
        <h4 className="empty-title">No activity</h4>
        <div className="empty-subtitle">{props.subtitle}</div>

        <div className="empty-action">{renderActionButton()}</div>
      </div>
    </div>
  );
};

export default NoActivityPanel;
