import { c } from "./userWeightActions";

export const initialState = {
  weights: [],
};

const userWeighReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.USER_WEIGHT_ENTRIES_FETCH_SUCCESS: {
      return {
        ...state,
        weights: action.weights,
      };
    }
    case c.USER_WEIGHT_DELETE_SUCCESS: {
      const deletedWeight = action.weight;
      const filteredWeights = state.weights.filter((weight) => weight.id !== deletedWeight.id);

      return {
        ...state,
        weights: filteredWeights,
      };
    }
    default:
      return state;
  }
};

export default userWeighReducer;
