import { push } from "connected-react-router";
import globalActions from "../modules/global/globalActions";
import { ewoloConstants } from "ewolo-core-js";

export const getErrorCode = (error) => {
  if (error && error.name && error.name === "RequestError") {
    const r = error.response;
    return r.status;
  }
  return 1;
};

export const handleError = ({ error, dispatch, notificationMessage, codeMessages }) => {
  const errorCode = getErrorCode(error);

  if (codeMessages && codeMessages[errorCode]) {
    dispatch(
      globalActions.userNotificationAdd({
        type: "ERROR",
        text: codeMessages[errorCode],
      })
    );
    return;
  }

  if (errorCode === 403) {
    dispatch(
      globalActions.userNotificationAdd({
        type: "ERROR",
        text: "Operation not permitted.",
      })
    );
    return;
  }

  if (errorCode === 401) {
    dispatch(
      globalActions.userNotificationAdd({
        type: "ERROR",
        text: "Unauthorized to perform this action. Please try logging in again.",
      })
    );
    dispatch(push(`${ewoloConstants.api.routes.ROUTE_LOGIN}`));
    return;
  }

  if (errorCode === 409) {
    dispatch(
      globalActions.userNotificationAdd({
        type: "ERROR",
        text: "Duplicate entry",
      })
    );
    return;
  }

  dispatch(
    globalActions.userNotificationAdd({
      type: "ERROR",
      text: notificationMessage,
    })
  );

  console.error(error);
};
