import React from "react";
import { ewoloUtil } from "ewolo-core-js";
import Modal from "../generic/Modal";

class WeightEntryView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteConfirmModal: false,
    };
  }

  handleModalWeightDeleteExecute = () => {
    this.props.doDeleteWeightEntryThunk(this.props.weight);
  };

  handleModalWeightDeleteCancel = () => {
    const state = this.state;
    this.setState({
      ...state,
      showDeleteConfirmModal: false,
    });
  };

  handleWeightDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const state = this.state;
    this.setState({
      ...state,
      showDeleteConfirmModal: true,
    });
  };

  render() {
    return (
      <div className="accordion-item">
        <Modal
          doModalActionCancel={this.handleModalWeightDeleteCancel}
          doModalActionExecute={this.handleModalWeightDeleteExecute}
          modalActionExecute="Delete"
          showModal={this.state.showDeleteConfirmModal}
          size="sm"
          title="Delete weight entry"
          content={["Are you sure you want to delete this weight entry?"]}
        />

        <div className="accordion-header weight-panel">
          <div className="columns">
            <div className="column col-xs-5 col-3">{this.props.weight.date}</div>
            <div className="column col-xs-5 col-8 weight-panel-body">
              {this.props.weight.body}{" "}
              {this.props.weight.units !== this.props.defaultUnits
                ? ewoloUtil.unitsToText(this.props.weight.units)
                : ""}
            </div>
            <div className="column col-xs-2 col-1 text-right">
              <button
                className="btn btn-action circle btn-exercise-action tooltip"
                data-tooltip="Delete weight entry"
                type="button"
                onClick={this.handleWeightDelete}
              >
                <i className="icon icon-delete" />
              </button>

              {/*
              <div className={"dropdown dropdown-right"}>
                <button
                  className="btn btn-action circle dropdown-toggle"
                  type="button"
                  onClick={this.handleWorkoutMenuClick}>
                  <i className="icon icon-menu"></i>
                </button>

                <ul
                  className={"menu" + (this.state.isMenuActive
                  ? ' active'
                  : '')}>
                  <li className="menu-item">
                    <a href="#/workout-copy" onClick={this.handleWorkoutEdit}>
                      <i className="fa fa-pencil" aria-hidden="true"></i>Edit workout {this.props.workout.date}</a>
                  </li>
                  <li className="menu-item">
                    <a href="#/workout-copy" onClick={this.handleWorkoutCopy}>
                      <i className="fa fa-clone" aria-hidden="true"></i>Copy workout {this.props.workout.date}</a>
                  </li>
                  <li className="menu-item">
                    <a href="#/workout-delete" onClick={this.handleWorkoutDelete}>
                      <i className="fa fa-trash" aria-hidden="true"></i>Delete workout {this.props.workout.date}</a>
                  </li>
                  <li className="menu-item">
                    <a onClick={this.handleWorkoutShare}>
                      <i className="fa fa-share" aria-hidden="true"></i>Share workout {this.props.workout.date}</a>
                  </li>
                </ul>
              </div>
              */}
            </div>
          </div>
        </div>
        <div className="accordion-body" />
      </div>
    );
  }
}

export default WeightEntryView;
