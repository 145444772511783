import moment from "moment";
import normalizeData from "./mathService";

export const segregateWorkoutsByMonth = (workouts) => {
  const result = [];
  const keyFormat = "MMMM, YYYY";

  if (!workouts.length) {
    return result;
  }

  let currentMonth = {
    key: moment(workouts[0].date).format(keyFormat),
    workouts: [],
  };

  for (const workout of workouts) {
    const workoutMonth = moment(workout.date).format(keyFormat);

    if (currentMonth.key === workoutMonth) {
      currentMonth.workouts.push(workout);
    } else {
      result.push(currentMonth);

      currentMonth = {
        key: workoutMonth,
        workouts: [workout],
      };
    }
  }

  result.push(currentMonth);

  return result;
};

export const getChartData = (workoutsAnalysis, units = 1) => {
  // const maxVolumeNormalizationRange = units === 1 ? 500 : 200;
  const maxVolumeNormalizationRange = 100;

  const result = {
    rows: [],
    columns: [
      {
        type: "date",
        label: "Date",
      },
      {
        type: "number",
        label: `Volume (normalized 0-100)`,
      },
      {
        type: "number",
        label: "Body weight (normalized 0-100)",
      },
      {
        type: "number",
        label: "Run distance (normalized 0-100)",
      },
    ],
  };

  if (workoutsAnalysis.length === 0) {
    result.rows.push([new Date("2018-01-01"), 0, 0, 0]);
  } else {
    // normalize volume first
    const normalizedVolumeData = normalizeData(workoutsAnalysis, 1, {
      max: maxVolumeNormalizationRange,
    });
    // normalize weight next
    const normalizedVolumeWeightData = normalizeData(normalizedVolumeData, 2, {
      max: maxVolumeNormalizationRange,
    });
    // then normalize run distance
    const normalizedVolumeWeightDistanceData = normalizeData(normalizedVolumeWeightData, 3, {
      max: maxVolumeNormalizationRange,
    });

    for (const item of normalizedVolumeWeightDistanceData) {
      item[0] = new Date(item[0]);

      // required because we return null for unknown values;
      item[1] = item[1] ? item[1] : 0;
      item[2] = item[2] ? item[2] : 0;
      item[3] = item[3] ? item[3] : 0;

      result.rows.push(item);
    }
  }

  return result;
};

export const orderWorkoutsByDate = (workouts) => {
  const result = [];

  for (const workout of Object.values(workouts)) {
    result.push({
      ...workout,
    });
  }

  result.sort((a, b) => {
    return moment(b.date).unix() - moment(a.date).unix();
  });

  return result;
};

export const getLastDate = (workouts) => {
  let lastDateUnix = moment().unix();

  for (const workout of Object.values(workouts)) {
    const unix = moment(workout.date).unix();
    if (unix <= lastDateUnix) {
      lastDateUnix = unix;
    }
  }

  return moment.unix(lastDateUnix).format("YYYY-MM-DD");
};
