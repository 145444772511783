import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { getChartData } from "../../services/workoutsService";
import { getDateRangeSelectionValues } from "../../services/workoutAnalyticsService";

import AnalyticsFilter from "../generic/AnalyticsFilter";
import DateVsWeightAndBodyWeightScatterChart from "../charts/DateVsWeightAndBodyWeightScatterChart";
import userWorkoutsActions from "../../modules/user-workouts/userWorkoutsActions";

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    defaultUnits: state.user.data.units,
    workoutsAnalysis: state.user.workouts.workoutsAnalysis,
    dirtyWorkoutsAnalysis: state.user.workouts.dirtyWorkoutsAnalysis,
    userExerciseNames: ["", ...state.user.data.userExerciseNames],
  };
};

const mapDispatchToProps = {
  doFetchUserWorkoutsAnalysisThunk: userWorkoutsActions.fetchUserWorkoutsAnalysisThunk,
};

class DashboardProgress extends Component {
  constructor(props) {
    super(props);

    const workoutsAnalysisChartData = getChartData(props.workoutsAnalysis, props.defaultUnits);
    const { dateBefore, dateAfter } = getDateRangeSelectionValues({ dateRange: "last-year" });

    this.state = {
      chartEvents: [
        {
          eventName: "select",
          callback(Chart) {
            // Returns Chart so you can access props and  the ChartWrapper object from
            // chart.wrapper console.log('Selected ', Chart.chart.getSelection());
          },
        },
      ],
      rows: workoutsAnalysisChartData.rows,
      columns: workoutsAnalysisChartData.columns,
      filterExerciseName: "",
      filterDateBefore: dateBefore,
      filterDateAfter: dateAfter,
      workoutsAnalysis: null,
      isFetching: false,
    };
  }

  componentDidMount() {
    if (this.props.dirtyWorkoutsAnalysis) {
      this.props.doFetchUserWorkoutsAnalysisThunk(
        this.state.filterDateBefore,
        this.state.filterDateAfter,
        this.state.filterExerciseName
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // update chart if something changed most likely this will happen in the case
    // that we roll back to this page

    // note that since the dashboard progress chart is now normalized,
    // it is not necessary to reload it in case the default units changed!

    const newState = prevState;
    let update = false;

    if (nextProps.workoutsAnalysis !== prevState.workoutsAnalysis) {
      const workoutsAnalysisChartData = getChartData(nextProps.workoutsAnalysis, nextProps.defaultUnits);
      newState.rows = workoutsAnalysisChartData.rows;
      newState.columns = workoutsAnalysisChartData.columns;
      update = true;
    }

    // if the workout analysis is marked as dirty do a reload
    if (nextProps.dirtyWorkoutsAnalysis === true && newState.isFetching === false) {
      nextProps.doFetchUserWorkoutsAnalysisThunk(
        newState.filterDateBefore,
        newState.filterDateAfter,
        newState.filterExerciseName
      );
      newState.isFetching = true;
      update = true;
    }

    // however, stop fetching if it becomes unmarked
    if (nextProps.dirtyWorkoutsAnalysis === false) {
      newState.isFetching = false;
      update = true;
    }

    if (update) {
      return newState;
    }

    // Return null to indicate no change to state.
    return null;
  }

  /*
  componentDidUpdate(prevProps, prevState) {
    console.log('DashboardProgress update');
    console.log(this.state);
  }
  */

  doApplyFilter = ({ exerciseNameIndex, dateBefore, dateAfter }) => {
    const exerciseName = this.props.userExerciseNames[exerciseNameIndex];

    const newState = this.state;
    newState.filterExerciseName = exerciseName;
    newState.filterDateBefore = dateBefore;
    newState.filterDateAfter = dateAfter;

    this.setState(newState);

    this.props.doFetchUserWorkoutsAnalysisThunk(dateBefore, dateAfter, exerciseName);
  };

  render() {
    return (
      <div>
        <div className="container grid-md section-content">
          <div className="columns">
            <div className="column col-12">
              <h3>Progress</h3>
            </div>

            <div className="column col-12">
              <AnalyticsFilter
                exerciseNames={this.props.userExerciseNames}
                doApplyFilter={this.doApplyFilter}
                selectedDateRange="last-year"
              />
            </div>
          </div>
        </div>

        <div className="container grid-xl section-content">
          <div className="columns">
            <div className="column col-12">
              <DateVsWeightAndBodyWeightScatterChart
                units={this.props.defaultUnits}
                rows={this.state.rows}
                columns={this.state.columns}
                chartEvents={this.state.chartEvents}
              />
            </div>
          </div>
        </div>

        <div className="container grid-md section-content">
          <div className="columns">
            <div className="column col-12">
              <p className="no-text">
                Also check the&nbsp;
                <Link to="/analytics">analytics</Link>
                &nbsp;page for an in-depth exercise analysis.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProgress);
