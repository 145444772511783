import { push } from "connected-react-router";
import { ewoloUtil, ewoloConstants } from "ewolo-core-js";

import { handleError } from "../../common/errorHandler";

import globalActions from "../global/globalActions";
import userDataActions from "../user-data/userDataActions";

export const c = Object.freeze({
  ACCOUNT_SET_PASSWORD_DATA: "ACCOUNT-SET-PASSWORD-DATA",
  ACCOUNT_PASSWORD_UPDATE_SUCCESS: "ACCOUNT-PASSWORD-UPDATE-SUCCESS",
  ACCOUNT_SET_DATA: "ACCOUNT-SET-DATA",
});

const accountActions = {
  accountSetPasswordData: (oldPassword, password) => {
    return {
      type: c.ACCOUNT_SET_PASSWORD_DATA,
      oldPassword,
      password,
    };
  },
  accountPasswordUpdateSuccess: () => {
    return {
      type: c.ACCOUNT_PASSWORD_UPDATE_SUCCESS,
    };
  },
  accountPasswordUpdateThunk: () => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;

      if (!authToken) {
        return Promise.resolve().then(() => {
          dispatch(push("/"));
        });
      }

      const { oldPassword, password } = getState().account;

      dispatch(globalActions.taskStart());

      const promise = ewoloUtil.getApiRequest({
        route: "/credentials",
        method: "POST",
        body: { oldPassword, password },
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then(() => {
          dispatch(accountActions.accountPasswordUpdateSuccess());
          dispatch(
            globalActions.userNotificationAdd({
              type: "SUCCESS",
              text: "Updated password",
              markPreviousAsRead: true,
            })
          );
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage: `An error occured when updating password`,
          });
        })
        .then(() => {
          // poor man's substitute for finally
          dispatch(globalActions.taskEnd());
        });
    };
  },
  accountSetData: ({ name, units, sex }) => {
    return {
      type: c.ACCOUNT_SET_DATA,
      name,
      units,
      sex,
    };
  },
  accountDataUpdateThunk: () => {
    return (dispatch, getState) => {
      const { authToken, exerciseNames, userExerciseNames, email, id } = getState().user.data;

      if (!authToken) {
        return Promise.resolve().then(() => {
          dispatch(push("/"));
        });
      }

      const { name, units, sex } = getState().account;

      dispatch(globalActions.taskStart());

      const promise = ewoloUtil.getApiRequest({
        route: `${ewoloConstants.api.routes.ROUTE_USERS}/${id}`,
        method: "PUT",
        body: { name, units, sex },
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then(() => {
          dispatch(userDataActions.userDataSet(exerciseNames, userExerciseNames, name, email, units, sex));
          dispatch(
            globalActions.userNotificationAdd({
              type: "SUCCESS",
              text: "Updated account settings",
              markPreviousAsRead: true,
            })
          );
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage: `An error occured when updating account`,
          });
        })
        .then(() => {
          // poor man's substitute for finally
          dispatch(globalActions.taskEnd());
        });
    };
  },
  /*,
  accountDataFetchThunk: (displaySuccessNotification) => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;

      if (!authToken) {
        return Promise.resolve()
          .then(() => {
            dispatch(push('/'));
          });
      }

      const userId = getState().user.data.id;
      
      dispatch(globalActions.taskStart());

      const promise = ewoloUtil.getApiRequest({
        route: '/users/' + userId,
        method: 'GET',
        authToken: authToken
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then(body => {
          dispatch(accountActions.accountSetData({name: body.name, units: body.units}));
          if (displaySuccessNotification) {
            dispatch(globalActions.('SUCCESS', 'Successfully got account data', true));
          }
        })
        .catch(error => {
          handleError(error);
          dispatch(globalActions.userNotificationAdd('ERROR', `An error occured when getting account information`));
        })
        .then(() => { // poor man's substitute for finally
          dispatch(globalActions.taskEnd());
        });
    }
  }
  */
};

export default accountActions;
