import React, { Component } from "react";
import moment from "moment"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import { ewoloUtil } from "ewolo-core-js";

import LogRunEntryView from "./LogRunEntryView";
import UserNotificationBar from "../notification/UserNotificationBar";
// import Modal from "../generic/Modal";
import { EwoloFormHintSplit } from "../generic/EwoloFormHint";

import logRunActions from "../../modules/log-run/logRunActions";
import userRunActions from "../../modules/user-run/userRunActions";
import NoActivityPanel from "../generic/NoActivityPanel";
import { getDuration } from "../../services/timeService";

const mapStateToProps = (state) => {
  return {
    logRun: state.user.logRun,
    runs: state.user.userRun.runs,
    authToken: state.user.data.authToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchUserRunsThunk: () => {
      dispatch(userRunActions.fetchUserRunsThunk());
    },
    doLogRunSetData: ({ datetime, meters, seconds }) => {
      dispatch(logRunActions.logRunSetData({ datetime, meters, seconds }));
    },
    doLogRunSaveThunk: () => {
      dispatch(logRunActions.logRunSaveThunk());
    },
    doDeleteRunThunk: (run) => {
      dispatch(userRunActions.userRunDeleteThunk(run));
    },
  };
};

const getLogRunFormState = ({ meters = 0, seconds = 0 }) => {
  const { h, m, s } = getDuration(seconds);

  return {
    km: meters / 1000,
    h,
    m,
    s,
  };
};

const getLogRunReducerState = ({ km = 0, h = 0, m = 0, s = 0 }) => {
  return {
    meters: km * 1000,
    seconds: s + m * 60 + h * 3600,
  };
};

/**
 * Please note that this is a bit of a fishy component at it maintains it's
 * own local form state and passes that information out to the redux store.
 *
 * What really makes it fishy is the lack of auto-update when form data
 * changes from the outside, for e.g. all forms usually reset after a save.
 * In this instance we need to do the resetting manually, capice?
 */
class LogRun extends Component {
  constructor(props) {
    super(props);

    this.state = getLogRunFormState({ meters: props.logRun.meters, seconds: props.logRun.seconds });
  }

  componentDidMount() {
    // since this route can be loaded from an unauthenticated source, add guard
    if (this.props.authToken) {
      this.props.doFetchUserRunsThunk();
    }

    this.props.doLogRunSetData({});
  }

  // unused
  handleNoRunsBtnActionClick = (event) => {
    event.preventDefault();
    // console.log(event);
  };

  handleDatetimeChange = (event) => {
    this.props.formUpdated();

    this.props.doLogRunSetData({
      datetime: event.target.value,
      meters: this.props.logRun.meters,
      seconds: this.props.logRun.seconds,
    });
  };

  handleDistanceChange = (event) => {
    this.props.formUpdated();

    const newState = {
      ...this.state,
      km: event.target.value,
    };

    const reducerState = getLogRunReducerState(newState);

    this.setState(newState);

    this.props.doLogRunSetData({
      datetime: this.props.logRun.datetime,
      ...reducerState,
    });

    // console.log(newState, reducerState);
  };

  handleTimeChange = (propertyName, value) => {
    this.props.formUpdated();

    const newState = {
      ...this.state,
    };
    newState[propertyName] = parseInt(value, 10);

    const reducerState = getLogRunReducerState(newState);

    this.setState(newState);

    this.props.doLogRunSetData({
      datetime: this.props.logRun.datetime,
      ...reducerState,
    });

    // console.log(newState, reducerState);
  };

  handleSecondsChange = (event) => {
    this.handleTimeChange("s", event.target.value);
  };

  handleMinutesChange = (event) => {
    this.handleTimeChange("m", event.target.value);
  };

  handleHoursChange = (event) => {
    this.handleTimeChange("h", event.target.value);
  };

  handleSave = (event) => {
    event.preventDefault();
    this.props.formSaved();
    this.props.doLogRunSaveThunk();
    this.props.doFetchUserRunsThunk();

    // manually rest local state to 0
    this.setState(getLogRunFormState({}));
  };

  isSaveDisabled = () => {
    if (this.props.logRun.dateFormHint) {
      return true;
    }
    return false;
  };

  render() {
    const pageTitle = <h3>Log a run</h3>;

    return (
      <div>
        <UserNotificationBar />

        <div className="container grid-xs section-content">
          <div className="columns">
            <div className="column col-12">{pageTitle}</div>
          </div>

          <div className="columns">
            <div className="column col-12">
              <form className="form-horizontal">
                <div className="form-group">
                  <div className="col-3">
                    <label className="form-label">Date</label>
                  </div>
                  <div className="col-9">
                    <input
                      className="form-input"
                      // type="datetime-local"
                      type="date"
                      value={this.props.logRun.datetime}
                      onChange={this.handleDatetimeChange}
                    />
                  </div>
                </div>

                <EwoloFormHintSplit formHint={this.props.logRun.dateFormHint} />

                <div className="form-group">
                  <div className="col-3">
                    <label className="form-label">Distance</label>
                  </div>
                  <div className="col-6">
                    <div className="input-group">
                      <input
                        className="form-input"
                        type="number"
                        min="0"
                        max="1000"
                        step="0.01"
                        placeholder="0.0"
                        property="km"
                        value={this.state.km}
                        onChange={this.handleDistanceChange}
                        onFocus={ewoloUtil.setCursorAtEndOnFocus}
                      />
                      <span className="input-group-addon">km</span>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-3">
                    <label className="form-label">Time (hh:mm:ss)</label>
                  </div>

                  <div className="col-3">
                    <div className="input-group">
                      <input
                        className="form-input"
                        type="number"
                        placeholder="0"
                        min="0"
                        step="1"
                        max="1000"
                        property="h"
                        value={this.state.h}
                        onChange={this.handleHoursChange}
                        onFocus={ewoloUtil.setCursorAtEndOnFocus}
                      />
                      {/*<span class="input-group-addon">hh</span>*/}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="input-group">
                      <input
                        className="form-input"
                        type="number"
                        placeholder="0"
                        min="0"
                        step="1"
                        max="59"
                        property="m"
                        value={this.state.m}
                        onChange={this.handleMinutesChange}
                        onFocus={ewoloUtil.setCursorAtEndOnFocus}
                      />
                      {/*<span class="input-group-addon">hh</span>*/}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="input-group">
                      <input
                        className="form-input"
                        type="number"
                        placeholder="0"
                        min="0"
                        step="1"
                        max="59"
                        property="s"
                        value={this.state.s}
                        onChange={this.handleSecondsChange}
                        onFocus={ewoloUtil.setCursorAtEndOnFocus}
                      />
                      {/*<span class="input-group-addon">hh</span>*/}
                    </div>
                  </div>
                </div>

                <div className="form-group form-group-action">
                  <div className="col-12 text-center">
                    <button
                      id="btn-save-workout"
                      className={"btn btn-primary btn-lg" + (this.isSaveDisabled() ? " disabled" : "")}
                      onClick={this.handleSave}
                    >
                      Save Run
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.renderRuns(this.props.authToken, this.props.runs)}
      </div>
    );
  }

  renderRuns(authToken, runs) {
    if (!authToken) {
      return null;
    }

    return (
      <div className="container grid-xs section-content">
        <div className="columns margin-top-1rem">
          <div className="column col-12">
            <h3>Runs</h3>

            {runs.length === 0 && <NoActivityPanel subtitle="No running activity logged!" />}
            {runs.length > 0 && <div className="accordion width-100">{this.renderRunEntries(runs)}</div>}
          </div>
        </div>
      </div>
    );
  }

  renderRunEntries(runs) {
    return runs.map((run) => {
      return <LogRunEntryView key={run.id} run={run} doDeleteRunThunk={this.props.doDeleteRunThunk} />;
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogRun);
