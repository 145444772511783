import React from "react";

const Privacy = (props) => {
  return (
    <div className="container grid-sm section-content">
      <div className="columns">
        <div className="column col-12">
          <h3>Privacy policy</h3>

          <h6>
            <u>Last updated</u>: May 20, 2018
          </h6>
          <p>
            When you create your account, you are giving this site information about yourself that is collected. This
            includes your name, your email address and your password (which is encrypted before being stored). No other
            personally identifiable information is stored. You can change your personal information at any time by
            logging into the site.
          </p>
          <p>No personal information is collected when browsing this site anonymously.</p>
          <p>
            Any workouts or any fitness activity logged on this site may be anonymized, analyzed and shared with other
            users to improve the product. However,{" "}
            <b>any personally identifiable data will never be shared without your explicit consent</b>. When you close
            your account, all your personal information is destroyed however a backup is kept for a limited time (30
            business days), after which all account data is permanently deleted.
          </p>
          <p>
            Ewolo runs on Linode and the site operators are the only ones, apart from Linode’s employees, who have
            access to those servers.
          </p>
          {/*Transactional emails are served through Postmark.*/}
          <p>
            The site does not show ads. <b>It also does not sell data to any third party.</b> Google Analytics is used
            to track anonymized visits, keywords search and to get to know general information about the audience.
            Google Analytics uses{" "}
            <a
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              target="_blank"
              rel="noopener noreferrer"
            >
              cookies
            </a>
            .
          </p>

          <p>
            Email communication is done via Mailchimp and your name and email address is stored on their services.
            Nobody apart from Ewolo's operators has access to the Mailchimp account. Mailchimp is fully GDPR compliant
            and Mailchimp's privacy policy can be found <a href="https://mailchimp.com/legal/privacy/">here</a>.
          </p>

          {/*We use Stripe to collect payments made to access the paid version. We do not store credit card information or anything concerning the transactions themselves on our servers. As per the open source library we use to process the payments (Laravel Cashier), we store the last 4 digits of the credit card, the brand name (VISA or MasterCard). As a user, you are identified on Stripe by a random number that they generate and use.*/}
          <p>You will be notified of any changes to this document via this page.</p>
          <p>Ewolo does not specifically market to children under the age of 13 years old.</p>
          <p>Ewolo is proudly built on open-source projects, a majority of which are hosted on Github.</p>

          <h4>GDPR compliance</h4>
          {/*
          <p>As a provider of an internet service, we</p>
          <ul className="text">
            <li>
              Do not collect any personally identifiable information apart from
              your name and email address.
            </li>
            <li>
              Do not share any personally identifiable information with any 3rd
              party tracking services.
            </li>
            <li>
              Use Google Analytics for basic anonymized website analytics
              tracking and with ip anonymization turned on.
            </li>
            <li>
              Collect personal information regarding your fitness that you
              provide but is never shared with anyone else. The fitness
              information that you provide is however aggregated, anonymised and
              may be used to enhance the product for other users.
            </li>
          </ul>
          */}

          <p>
            In addition to the above safeguards regarding your privacy, as a user of this service you can at any time,
          </p>
          <ul className="text">
            <li>
              Update your personal information at anytime through the <a href="/account">account</a> page.
            </li>
            <li>Opt out of receiving email communication from us.</li>
            <li>
              Request that your account be deleted by sending an email to{" "}
              <a href="mailto:vic@ewolo.fitness">vic@ewolo.fitness</a>. This will delete your account with us along with
              your email address from our email communication channel. Note that while your account is deleted
              immediately, it may take up to 30 days to fully delete all your information from our systems.
            </li>
            <li>
              Request for a copy of all your data with us by sending an email to{" "}
              <a href="mailto:vic@ewolo.fitness">vic@ewolo.fitness</a>.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
