const appNotifications = [
  {
    id: "custom-weight-units",
    text: "Ewolo 2.0.0: Ability to set default units for weight and choose between lbs and kgs when logging exercises.",
    show: "all",
  },
  {
    id: "standardized-menus",
    text: "Ewolo 2.6.3: Dashboard progress filters + standardized menus, click to open and click to close.",
    show: "all",
  },
  {
    id: "analytics",
    text: "Ewolo 3.0.0: Check the analytics page for an in-depth exercise analysis!",
    show: "logged-in",
  },
  {
    id: "body-weight",
    text: "Ewolo v4: Ability to track body weight and compare with workout progress!",
    show: "all",
  },
  {
    id: "log-runs",
    text: "Ewolo v5: Ability to log running activity and compare with body weight and/or workout progress!",
    show: "all",
  },
];

export const getAppNotification = () => {
  return appNotifications[4];
  // return null;
};
