import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./Header.css";
import ewoloLogo from "./ewolo-logo.png";

import * as avatarService from "../account/avatarService";

const mapStateToProps = (state) => {
  return {
    authToken: state.user.data.authToken,
    name: state.user.data.name,
    email: state.user.data.email,
    sex: state.user.data.sex,
  };
};

class Header extends Component {
  state = {
    docsSidebarClass: "",
    sidebarCloseClass: "",
    isMenuActive: false,
    isMiscMenuActive: false,
    isLogOtherMenuActive: false,
  };

  handleSidebarShowClick = () => {
    this.setState({ docsSidebarClass: "show", sidebarCloseClass: "show" });
  };

  handleSidebarCloseClick = () => {
    this.setState({ docsSidebarClass: "", sidebarCloseClass: "" });
  };

  handleAccountMenuClick = (event) => {
    event.preventDefault();
    const newState = this.state;
    newState.isMenuActive = !newState.isMenuActive;
    this.setState(newState);
  };

  handleAccountMenuItemlick = (event) => {
    const newState = this.state;
    newState.isMenuActive = false;
    this.setState(newState);
  };

  handleMiscMenuClick = (event) => {
    event.preventDefault();
    const newState = this.state;
    newState.isMiscMenuActive = !newState.isMiscMenuActive;
    this.setState(newState);
  };

  handleLogOtherMenuClick = (event) => {
    event.preventDefault();
    const newState = this.state;
    newState.isLogOtherMenuActive = !newState.isLogOtherMenuActive;
    this.setState(newState);
  };

  handleMiscMenuItemClick = (event) => {
    const newState = this.state;
    newState.isMiscMenuActive = false;
    this.setState(newState);
  };

  render() {
    const avatar = avatarService.getAvatar(this.props.sex);

    return (
      <div id="app-header" className="bg-gray">
        <div className="container grid-xl ">
          <header className="navbar">
            <section className="navbar-section">
              <button
                id="sidebar-show"
                className="btn btn-link btn-action btn-lg"
                onClick={this.handleSidebarShowClick}
              >
                <i className="icon icon-menu" />
              </button>

              <Link to="/" className="navbar-brand mr-10">
                <img src={ewoloLogo} className="img-responsive" alt="ewolo logo" />
              </Link>
            </section>
            {this.renderSecondSection()}
            <section className="navbar-section navbar-content account-section">
              {this.renderThirdSection(avatar)}
            </section>
          </header>
        </div>
        <div id="sidebar" className="container grid-md">
          <div className="columns">
            <div className={"docs-sidebar column col-3 col-sm-12 " + this.state.docsSidebarClass}>
              <div className="text-right">
                <button
                  id="btn-sidebar-close"
                  className="btn btn-link btn-action btn-lg"
                  onClick={this.handleSidebarCloseClick}
                >
                  <i className="icon icon-cross" />
                </button>
              </div>

              <ul className="docs-nav nav">
                <li className="brand">
                  <img src={ewoloLogo} className="img-responsive" alt="ewolo logo" />
                </li>
                <li className="nav-item">
                  <NavLink exact={true} to="/" onClick={this.handleSidebarCloseClick}>
                    {this.props.authToken ? "Dashboard" : "Home"}
                  </NavLink>
                </li>
                {this.props.authToken && (
                  <li className="nav-item">
                    <NavLink exact={true} to="/analytics" onClick={this.handleSidebarCloseClick}>
                      Analytics
                    </NavLink>
                  </li>
                )}
                <li className="nav-item">
                  <NavLink exact={true} to="/log-workout" onClick={this.handleSidebarCloseClick}>
                    Log workout
                  </NavLink>
                </li>

                <li className="nav-item">
                  <span>Other</span>
                  <ul className="nav">
                    <li className="nav-item">
                      <NavLink exact={true} to="/log-weight" onClick={this.handleSidebarCloseClick}>
                        Log weight
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact={true} to="/log-run" onClick={this.handleSidebarCloseClick}>
                        Log a run
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <span>Misc</span>
                  <ul className="nav">
                    <li className="nav-item">
                      <NavLink exact={true} to="/blog" onClick={this.handleSidebarCloseClick}>
                        Blog
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact={true} to="/why-ewolo" onClick={this.handleSidebarCloseClick}>
                        Why Ewolo?
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="divider" />

                <li className="nav-item">
                  <div className="tile">
                    <div className="tile-icon">
                      <img src={avatar} className="avatar" alt="avatar" />
                    </div>
                    <div className="tile-content">{this.props.email}</div>
                  </div>

                  <ul className="nav">
                    {this.props.authToken && (
                      <li className="nav-item">
                        <i className="fa fa-fw fa-cogs" aria-hidden="true" />
                        <NavLink exact={true} to="/account" onClick={this.handleSidebarCloseClick}>
                          Account
                        </NavLink>
                      </li>
                    )}
                    {this.props.authToken && (
                      <li className="nav-item">
                        <i className="fa fa-fw fa-sign-out" aria-hidden="true" />
                        <NavLink exact={true} to="/logout" onClick={this.handleSidebarCloseClick}>
                          Logout
                        </NavLink>
                      </li>
                    )}
                    {!this.props.authToken && (
                      <li className="nav-item">
                        <i className="fa fa-fw fa-user-plus" aria-hidden="true" />
                        <NavLink exact={true} to="/signup" onClick={this.handleSidebarCloseClick}>
                          Sign-up
                        </NavLink>
                      </li>
                    )}
                    {!this.props.authToken && (
                      <li className="nav-item">
                        <i className="fa fa-fw fa-sign-in" aria-hidden="true" />
                        <NavLink exact={true} to="/login" onClick={this.handleSidebarCloseClick}>
                          Login
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
            <a
              id="sidebar-close"
              className={"docs-nav-clear " + this.state.sidebarCloseClass}
              onClick={this.handleSidebarCloseClick}
            >
              &nbsp;
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderSecondSection() {
    const logOtherDropdown = (
      <div className="dropdown">
        <a className="btn btn-link dropdown-toggle" onClick={this.handleLogOtherMenuClick}>
          Log Other
          <i className="icon icon-caret" />
        </a>
        <ul
          onClick={this.handleLogOtherMenuClick}
          className={"menu" + (this.state.isLogOtherMenuActive ? " active" : "")}
        >
          <li className="menu-item">
            <NavLink exact={true} to="/log-weight" className="">
              Log Weight
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink exact={true} to="/log-run" className="">
              Log Run
            </NavLink>
          </li>
        </ul>
      </div>
    );

    const miscDropdown = (
      <div className="dropdown">
        <a className="btn btn-link dropdown-toggle" onClick={this.handleMiscMenuClick}>
          Misc
          <i className="icon icon-caret" />
        </a>
        <ul onClick={this.handleMiscMenuItemClick} className={"menu" + (this.state.isMiscMenuActive ? " active" : "")}>
          <li className="menu-item">
            <NavLink exact={true} to="/blog" className="">
              Blog
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink exact={true} to="/why-ewolo" className="">
              Why Ewolo?
            </NavLink>
          </li>
        </ul>
      </div>
    );

    const defaultLinks = (
      <section className="navbar-section navbar-content">
        <NavLink exact={true} to="/" className="btn btn-link">
          Home
        </NavLink>
        <NavLink exact={true} to="/log-workout" className="btn btn-link">
          Log workout
        </NavLink>
        {logOtherDropdown}
        <NavLink exact={true} to="/blog" className="btn btn-link">
          Blog
        </NavLink>
        <NavLink exact={true} to="/why-ewolo" className="btn btn-link xtra">
          Why Ewolo?
        </NavLink>
      </section>
    );

    const loggedInLinks = (
      <section className="navbar-section navbar-content">
        <NavLink exact={true} to="/" className="btn btn-link">
          Dashboard
        </NavLink>
        <NavLink exact={true} to="/analytics" className="btn btn-link">
          Analytics
        </NavLink>
        <NavLink exact={true} to="/log-workout" className="btn btn-link">
          Log workout
        </NavLink>
        {logOtherDropdown}
        {miscDropdown}
      </section>
    );

    return this.props.authToken ? loggedInLinks : defaultLinks;
  }

  renderThirdSection(avatar) {
    const userAccountMenu = (
      <div className="dropdown dropdown-right">
        <a className="btn btn-link dropdown-toggle" tabIndex="0" onClick={this.handleAccountMenuClick}>
          {/*this.props.name*/}
          <figure
            id="account-avatar"
            className="avatar avatar-lg"
            data-initial="U"
            style={{
              backgroundColor: "#5764c6",
            }}
          >
            <img src={avatar} alt="avatar" />
          </figure>
          <i className="icon icon-caret" />

          {/*
          <i className="fa fa-user" aria-hidden="true"></i>
          <i className="icon icon-caret"></i>
          */}
        </a>
        <ul onClick={this.handleAccountMenuItemlick} className={"menu" + (this.state.isMenuActive ? " active" : "")}>
          <li className="menu-item">
            <div className="tile tile-centered">{this.props.email}</div>
          </li>
          <li className="menu-item">
            <Link exact={"true"} to="/account">
              <i className="fa fa-fw fa-cogs" aria-hidden="true" />
              Account
            </Link>
          </li>
          <li className="menu-item">
            <Link exact={"true"} to="/logout">
              <i className="fa fa-fw fa-sign-out" aria-hidden="true" />
              Logout
            </Link>
          </li>
        </ul>
      </div>
    );

    const loginMenu = (
      <div>
        <NavLink exact={true} to="/signup" className="btn btn-link">
          <i className="fa fa-fw fa-user-plus" aria-hidden="true" />
          Sign-up
        </NavLink>
        <NavLink exact={true} to="/login" className="btn btn-link">
          <i className="fa fa-fw fa-sign-in" aria-hidden="true" />
          Login
        </NavLink>
      </div>
    );

    return this.props.authToken ? userAccountMenu : loginMenu;
  }
}

export default withRouter(connect(mapStateToProps)(Header)); // router integration required for NavLink to set active state
