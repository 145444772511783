import React from "react";
import { Link } from "react-router-dom";

import BlogTemplate from "./BlogTemplate";

import ewoloLogRunImage from "./media/ewolo-log-run.png";
import ewoloVictorLastYearsDataImage2 from "./media/ewolo-victor-last-years-data-2.png";
import ewoloSuperSetDisplayImage from "./media/ewolo-super-set-display.png";

const entry = {
  title: "Ewolo version 5 - Run tracking",
  url: "/blog/ewolo-version-5-run-tracking",
  author: "Victor Parmar",
  dateStr: "July 28, 2018",
};

const Version5RunTracking = (props) => {
  const ewoloLogRunImageText = "Log your runs using ewolo and compare it with your training progress!";
  const ewoloVictorLastYearsDataImage2Text =
    "Victor's progress chart for the past year. As you can see I have not managed to keep up with my training due to injuries and other family commitments :(";
  const ewoloSuperSetDisplayImageText = "Improved superset display!";

  return (
    <BlogTemplate entry={entry}>
      <p>
        Ewolo version 5 now allows you to track non-workout activity as well! You can now log your{" "}
        <Link to="/log-run">running activity</Link> in ewolo and most importantly, the dashboard analysis chart includes
        this data so that you can effectively evaluate your training progress :)
      </p>

      <figure className="figure blog">
        <a href={ewoloLogRunImage}>
          <img src={ewoloLogRunImage} className="img-responsive bordered p-centered" alt={ewoloLogRunImageText} />
        </a>
        <figcaption className="figure-caption text-center">{ewoloLogRunImageText}</figcaption>
      </figure>

      <p>
        Note that in order to use a metric that made sense - the run distance was taken. If you would rather like to see
        another field used, e.g. calories burnt, please get in <a href="mailto:vic@ewolo.fitness">touch</a>! Simlar to
        workout volume and body weight, the run distance is{" "}
        <u>
          <em>normalized to a value between 0 and 100</em>
        </u>
        . Also, as with other activity, run logging is a completely independent feature. While logging workouts, weights
        and runs work well together, you can use Ewolo to simply track just your runs that is what you prefer.
      </p>

      <figure className="figure blog">
        <a href={ewoloVictorLastYearsDataImage2}>
          <img
            src={ewoloVictorLastYearsDataImage2}
            className="img-responsive bordered p-centered"
            alt={ewoloVictorLastYearsDataImage2Text}
          />
        </a>
        <figcaption className="figure-caption text-center">{ewoloVictorLastYearsDataImage2Text}</figcaption>
      </figure>

      <p>Apart from run tracking, there have been a few UX improvements:</p>
      <ul className="text">
        <li>
          On the desktop, the dashboard progress chart now takes up more space for better visualization. Also, the
          default time period for the dashboard progress chart is now "last year".
        </li>
        <li>
          For a workout without any exercises, a message indicating there are no exercises is displayed in the notes
          section (only when there are no notes provided either).
        </li>
        <li>The display for super sets has been improved to better indicate which exercises are part of the set.</li>
      </ul>

      <figure className="figure blog">
        <a href={ewoloSuperSetDisplayImage}>
          <img
            src={ewoloSuperSetDisplayImage}
            className="img-responsive bordered p-centered"
            alt={ewoloSuperSetDisplayImageText}
          />
        </a>
        <figcaption className="figure-caption text-center">{ewoloSuperSetDisplayImageText}</figcaption>
      </figure>

      <p>I hope that you enjoy the new features. All feedback is welcome!</p>
    </BlogTemplate>
  );
};

const Version5RunTrackingEntry = {
  ...entry,
  component: Version5RunTracking,
};

export default Version5RunTrackingEntry;
