import React from "react";
import moment from "moment";
import Modal from "../generic/Modal";

import "./LogRunEntryView.css";
import { getDurationText, getSpeedInKmPerHr } from "../../services/timeService";

class LogRunEntryView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteConfirmModal: false,
    };
  }

  handleModalRunDeleteExecute = () => {
    this.props.doDeleteRunThunk(this.props.run);
  };

  handleModalRunDeleteCancel = () => {
    const state = this.state;
    this.setState({
      ...state,
      showDeleteConfirmModal: false,
    });
  };

  handleRunDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const state = this.state;
    this.setState({
      ...state,
      showDeleteConfirmModal: true,
    });
  };

  render() {
    const speed = getSpeedInKmPerHr(this.props.run.meters, this.props.run.seconds).toFixed(2) + " km/hr";

    return (
      <div className="accordion-item">
        <Modal
          doModalActionCancel={this.handleModalRunDeleteCancel}
          doModalActionExecute={this.handleModalRunDeleteExecute}
          modalActionExecute="Delete"
          showModal={this.state.showDeleteConfirmModal}
          size="sm"
          title="Delete run"
          content={["Are you sure you want to delete this run?"]}
        />

        <div className="accordion-header run-panel">
          <div className="columns">
            <div className="column col-xs-5 col-3">{moment(this.props.run.datetime).format("YYYY-MM-DD")}</div>
            <div className="column col-xs-5 col-8 run-panel-body">
              {(this.props.run.meters / 1000).toFixed(2)} km, {getDurationText(this.props.run.seconds)}, {speed}
            </div>
            <div className="column col-xs-2 col-1 text-right">
              <button
                className="btn btn-action circle btn-exercise-action tooltip"
                data-tooltip="Delete run"
                type="button"
                onClick={this.handleRunDelete}
              >
                <i className="icon icon-delete" />
              </button>

              {/*
              <div className={"dropdown dropdown-right"}>
                <button
                  className="btn btn-action circle dropdown-toggle"
                  type="button"
                  onClick={this.handleWorkoutMenuClick}>
                  <i className="icon icon-menu"></i>
                </button>

                <ul
                  className={"menu" + (this.state.isMenuActive
                  ? ' active'
                  : '')}>
                  <li className="menu-item">
                    <a href="#/workout-copy" onClick={this.handleWorkoutEdit}>
                      <i className="fa fa-pencil" aria-hidden="true"></i>Edit workout {this.props.workout.date}</a>
                  </li>
                  <li className="menu-item">
                    <a href="#/workout-copy" onClick={this.handleWorkoutCopy}>
                      <i className="fa fa-clone" aria-hidden="true"></i>Copy workout {this.props.workout.date}</a>
                  </li>
                  <li className="menu-item">
                    <a href="#/workout-delete" onClick={this.handleWorkoutDelete}>
                      <i className="fa fa-trash" aria-hidden="true"></i>Delete workout {this.props.workout.date}</a>
                  </li>
                  <li className="menu-item">
                    <a onClick={this.handleWorkoutShare}>
                      <i className="fa fa-share" aria-hidden="true"></i>Share workout {this.props.workout.date}</a>
                  </li>
                </ul>
              </div>
              */}
            </div>
          </div>
        </div>
        <div className="accordion-body" />
      </div>
    );
  }
}

export default LogRunEntryView;
