import React from "react";

import BlogTemplate from "./BlogTemplate";

import ewoloExerciseNotesLog from "./media/ewolo-exercise-notes-log.png";
import ewoloExerciseNotesView from "./media/ewolo-exercise-notes-view.png";
import ewoloExerciseMoveButtons from "./media/ewolo-exercise-move-buttons.png";
import ewoloExportData from "./media/ewolo-export-data.png";

const entry = {
  title: "Ewolo version 5.4 - Exercise notes and more",
  url: "/blog/ewolo-version-5-4-exercise-notes-and-more",
  author: "Victor Parmar",
  dateStr: "August 31, 2019",
};

const Version5_4ExerciseNotesAndMore = (props) => {
  const ewoloExerciseNotesLogImageText = "Enable exercise level notes.";
  const ewoloExerciseNotesViewImageText = "Exercise notes are also displayed.";
  const ewoloExerciseMoveButtonsImageText = "Easily order exercises for quicker logging.";
  const ewoloExportDataImageText = "Export all your data in a json format.";

  return (
    <BlogTemplate entry={entry}>
      <p>
        It's been a while since the last update. However, there have been quite a few improvements over the past year so
        I wanted to take an opportunity to showcase them all in this post :)
      </p>

      <p>
        The biggest new feature is exercise level logging. This feature can be enabled for the entire workout via a
        toggle and it works just like any other exercise field. The input is presented below the exercise name and on
        the dashboard, it is displayed along with the exercise details. It is also copied over when copying a workout.
      </p>

      <figure className="figure blog">
        <a href={ewoloExerciseNotesLog}>
          <img
            src={ewoloExerciseNotesLog}
            className="img-responsive bordered p-centered"
            alt={ewoloExerciseNotesLogImageText}
          />
        </a>
        <figcaption className="figure-caption text-center">{ewoloExerciseNotesLogImageText}</figcaption>
      </figure>

      <figure className="figure blog">
        <a href={ewoloExerciseNotesView}>
          <img
            src={ewoloExerciseNotesView}
            className="img-responsive bordered p-centered"
            alt={ewoloExerciseNotesViewImageText}
          />
        </a>
        <figcaption className="figure-caption text-center">{ewoloExerciseNotesViewImageText}</figcaption>
      </figure>

      <p>
        The reason why the exercise notes toggle is not setup as a button on the exercise block is because adding it
        would result in too many buttons. In my opinion, this is really an option for power users since you can add just
        about any amount of text in the workout notes. This is why, I decided to keep the current interface simple and
        put it as a workout level option.
      </p>

      <p>
        Ewolo has always had the philosophy of letting you log a workout as quickly as possible. Thus, logging an
        exercise now features move buttons that allow moving an exercise up or down. This is useful for supersets where
        you can quickly add multiple exercises of the same set and then order them after the fact, or, in the case where
        you delete an exercise by mistake, you can add it and then move it back up to the correct location!
      </p>

      <figure className="figure blog">
        <a href={ewoloExerciseMoveButtons}>
          <img
            src={ewoloExerciseMoveButtons}
            className="img-responsive bordered p-centered"
            alt={ewoloExerciseMoveButtonsImageText}
          />
        </a>
        <figcaption className="figure-caption text-center">{ewoloExerciseMoveButtonsImageText}</figcaption>
      </figure>

      <p>
        Finally, an oft requested feature by some users was the ability to export their data. This is now implemented
        and can be found under the user account page. Currently, all data is exported in a JSON format.
      </p>

      <figure className="figure blog">
        <a href={ewoloExportData}>
          <img src={ewoloExportData} className="img-responsive bordered p-centered" alt={ewoloExportDataImageText} />
        </a>
        <figcaption className="figure-caption text-center">{ewoloExportDataImageText}</figcaption>
      </figure>

      <p>I hope that you enjoy the new features. As always, I look forward to your feedback!</p>
    </BlogTemplate>
  );
};

const Version5_4ExerciseNotesAndMoreEntry = {
  ...entry,
  component: Version5_4ExerciseNotesAndMore,
};

export default Version5_4ExerciseNotesAndMoreEntry;
