const getDurationComponentText = (unit, str) => {
  return unit ? unit + " " + str + (unit > 1 ? "s" : "") : "";
};

export function getSpeedInKmPerHr(meters, seconds) {
  if (meters && seconds) {
    return ((meters / seconds) * 18) / 5;
  }
  return 0;
}

export function getDuration(seconds) {
  const s = seconds % 60;
  const h = parseInt(seconds / 3600, 10);
  const m = (seconds - h * 3600 - s) / 60;

  return {
    h,
    m,
    s,
  };
}

export function getDurationText(seconds) {
  const { h, m, s } = getDuration(seconds);

  const hs = getDurationComponentText(h, "hour");
  const ms = getDurationComponentText(m, "minute");
  const ss = getDurationComponentText(s, "second");

  const components = [];

  if (hs) {
    components.push(hs);
  }

  if (ms) {
    components.push(ms);
  }

  if (ss) {
    components.push(ss);
  }

  return components.length ? components.join(" ") : "0 seconds";
}
