import { push } from "connected-react-router";
import { ewoloUtil, ewoloConstants } from "ewolo-core-js";
import { handleError } from "../../common/errorHandler";

import globalActions from "../global/globalActions";
import userDataActions from "../user-data/userDataActions"; // eslint-disable-line no-unused-vars
import userWorkoutsActions from "../user-workouts/userWorkoutsActions";

export const c = {
  USER_WEIGHT_ENTRIES_FETCH_SUCCESS: "USER-WEIGHT-ENTRIES-FETCH-SUCCESS",
  USER_WEIGHT_DELETE_SUCCESS: "USER-WEIGHT-DELETE-SUCCESS",
};

const userWeightActions = {
  userWeightEntriesFetchSuccess: (weights) => {
    return {
      type: c.USER_WEIGHT_ENTRIES_FETCH_SUCCESS,
      weights,
    };
  },
  fetchUserWeightEntriesThunk: () => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;
      const userId = getState().user.data.id;
      const route = `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_WEIGHT}`;

      dispatch(globalActions.taskStart());

      const promise = ewoloUtil.getApiRequest({
        route: route,
        method: "GET",
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then((body) => {
          dispatch(userWeightActions.userWeightEntriesFetchSuccess(body));
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage: "An error occured when loading weight entries",
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },

  userWeightDeleteSuccess: (weight) => {
    return {
      type: c.USER_WEIGHT_DELETE_SUCCESS,
      weight,
    };
  },
  userWeightDeleteThunk: (weight) => {
    return (dispatch, getState) => {
      const authToken = getState().user.data.authToken;
      const userId = getState().user.data.id;
      const weightDate = weight.date;
      const weightId = weight.id;

      dispatch(globalActions.taskStart());

      let promise = ewoloUtil.getApiRequest({
        route: `${ewoloConstants.api.routes.ROUTE_USERS}/${userId}${ewoloConstants.api.routes.ROUTE_WEIGHT}/${weightId}`,
        method: "DELETE",
        authToken: authToken,
      });

      return promise
        .then(ewoloUtil.getApiResponse)
        .then((body) => {
          dispatch(userWorkoutsActions.userWorkoutsAnalysisMarkDirty(true));
          dispatch(userWeightActions.userWeightDeleteSuccess(weight));
          dispatch(
            globalActions.userNotificationAdd({
              type: "SUCCESS",
              text: "Deleted weight entry for " + weightDate,
            })
          );
          dispatch(push(`${ewoloConstants.api.routes.ROUTE_LOG_WEIGHT}`));
        })
        .catch((error) => {
          handleError({
            error,
            dispatch,
            notificationMessage:
              "An error occured when deleting weight for " + weightDate + ". Please wait a moment and try again.",
          });
        })
        .then(() => {
          dispatch(globalActions.taskEnd());
        });
    };
  },
};

export default userWeightActions;
