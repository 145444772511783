import { c } from "./userRunActions";

export const initialState = {
  runs: [],
};

const userRunReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.USER_RUNS_FETCH_SUCCESS: {
      return {
        ...state,
        runs: action.runs,
      };
    }
    case c.USER_RUN_DELETE_SUCCESS: {
      const deletedRun = action.run;
      const filteredRuns = state.runs.filter((run) => run.id !== deletedRun.id);

      return {
        ...state,
        runs: filteredRuns,
      };
    }
    default:
      return state;
  }
};

export default userRunReducer;
