const normalizationDefaults = {
  min: 0,
  max: 100,
  round: false,
};

/**
 * @param {*} arr The array containing data points, [{name: 'x', value: 3}, ...]
 * @param {*} prop The property value as a string or number in the case each data point is an array, i.e. 'value'
 * @param {*} defaultOverrides The overrides for default configuration
 */
export default function normalizeData(arr, prop, defaultOverrides) {
  let options;

  if (defaultOverrides) {
    options = Object.assign({}, normalizationDefaults, defaultOverrides);
  } else {
    options = Object.assign({}, normalizationDefaults);
  }

  // collect min and max value
  let min = null;
  let max = null;

  for (let obj of arr) {
    const value = obj[prop];

    if (value !== null) {
      if (min === null || value < min) {
        min = value;
      }
      if (max === null || value > max) {
        max = value;
      }
    }
  }

  const range = max - min;
  const newRange = options.max - options.min;

  for (let obj of arr) {
    let value = obj[prop];

    if (value !== null) {
      value = ((value - min) / range) * newRange + options.min;
      if (typeof options.round === "number") {
        const factor = Math.pow(10, options.round);
        value = Math.round(value * factor) / factor;
      }
      obj[prop] = value;
    }
  }
  return arr;
}
