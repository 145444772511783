import React from "react";

import "./WorkoutView.css";

import Modal from "../generic/Modal";
import WorkoutShareModal from "../publik/WorkoutShareModal";

import { ewoloUtil } from "ewolo-core-js";
import WorkoutViewExercises from "./WorkoutViewExercises";

class WorkoutView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteConfirmModal: false,
      showShareModal: false,
      isMenuActive: false,
    };
  }

  toggleViewWorkoutDetails = (event) => {
    event.preventDefault();
    this.props.doToggleViewWorkoutDetails(this.props.workout.id, !this.props.showWorkoutDetails);
  };

  handleModalWorkoutDeleteExecute = () => {
    this.props.doDeleteUserWorkoutThunk(this.props.workout.id, this.props.workout.date);
  };

  handleModalWorkoutDeleteCancel = () => {
    const state = this.state;
    this.setState({
      ...state,
      showDeleteConfirmModal: false,
    });
  };

  handleWorkoutDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const state = this.state;
    this.setState({
      ...state,
      showDeleteConfirmModal: true,
    });
  };

  handleWorkoutShare = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const state = this.state;
    this.setState({
      ...state,
      showShareModal: true,
    });
  };

  handleModalWorkoutShareClose = () => {
    const state = this.state;
    this.setState({
      ...state,
      showShareModal: false,
      isMenuActive: !state.isMenuActive,
    });
  };

  handleWorkoutCopy = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.doCopyWorkoutThunk(this.props.workout);
  };

  handleWorkoutEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.doEditWorkoutThunk(this.props.workout);
  };

  handleWorkoutMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newState = this.state;
    newState.isMenuActive = !newState.isMenuActive;
    this.setState(newState);
  };

  render() {
    const noTransitionCss = ewoloUtil.isMobileDevice() ? " no-transition " : "";

    return (
      <div className="accordion-item">
        <Modal
          doModalActionCancel={this.handleModalWorkoutDeleteCancel}
          doModalActionExecute={this.handleModalWorkoutDeleteExecute}
          modalActionExecute="Delete"
          showModal={this.state.showDeleteConfirmModal}
          size="sm"
          title="Delete workout"
          content={["Are you sure you want to delete this workout?"]}
        />

        <WorkoutShareModal
          doModalActionClose={this.handleModalWorkoutShareClose}
          showModal={this.state.showShareModal}
          workoutId={this.props.workout.id}
          workoutDate={this.props.workout.date}
        />

        <label className="accordion-header hand workout-panel" onClick={this.toggleViewWorkoutDetails}>
          <div className="columns">
            <div className="column col-xs-5 col-3">{this.props.workout.date}</div>
            <div className="column col-xs-5 col-8 workout-panel-notes">
              {!this.props.workout.notes && this.props.workout.exercises.length === 0
                ? "No exercises logged"
                : this.props.workout.notes}
            </div>
            <div className="column col-xs-2 col-1 text-right">
              {/*
            <button
              className="btn btn-action circle btn-exercise-action tooltip"
              data-tooltip="Delete workout"
              type="button"
              onClick={handleWorkoutDelete}>
              <i className="icon icon-delete"></i>
            </button>
            */}

              <div className={"dropdown dropdown-right"}>
                <button
                  className="btn btn-action circle dropdown-toggle"
                  type="button"
                  onClick={this.handleWorkoutMenuClick}
                >
                  <i className="icon icon-menu" />
                </button>

                <ul className={"menu" + (this.state.isMenuActive ? " active" : "")}>
                  <li className="menu-item">
                    <a href="#/workout-copy" onClick={this.handleWorkoutEdit}>
                      <i className="fa fa-pencil" aria-hidden="true" />
                      Edit workout {this.props.workout.date}
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="#/workout-copy" onClick={this.handleWorkoutCopy}>
                      <i className="fa fa-clone" aria-hidden="true" />
                      Copy workout {this.props.workout.date}
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="#/workout-delete" onClick={this.handleWorkoutDelete}>
                      <i className="fa fa-trash" aria-hidden="true" />
                      Delete workout {this.props.workout.date}
                    </a>
                  </li>
                  <li className="menu-item">
                    <a onClick={this.handleWorkoutShare}>
                      <i className="fa fa-share" aria-hidden="true" />
                      Share workout {this.props.workout.date}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </label>
        <div
          className={
            this.props.showWorkoutDetails ? "accordion-body-show" + noTransitionCss : "accordion-body" + noTransitionCss
          }
        >
          <WorkoutViewExercises exercises={this.props.workout.exercises} defaultUnits={this.props.defaultUnits} />
        </div>
      </div>
    );
  }
}

export default WorkoutView;
