import React from "react";
import { Link } from "react-router-dom";

import BlogTemplate from "./BlogTemplate";

import blogImage from "./media/bruce-mars-ZXq7xoo98b0.jpg";

const entry = {
  title: "How to waste time at the gym like a Pro",
  url: "/blog/how-to-waste-time-at-the-gym-like-a-pro",
  author: "Victor Parmar",
  dateStr: "October 5, 2019",
};

const BlogEntry = (props) => {
  const blogImageText = "The gym - a great place to hang out!";

  return (
    <BlogTemplate entry={entry}>
      <ul className="text">
        <li>
          Don't just talk on the phone, go for a full video chat. Also, make sure that you sit on a machine while
          chatting for maximum comfort. For best results, use wireless headphones so that you can continue chatting
          between reps.
        </li>
        <li>
          Related to the point above, if you cannot find someone to call, browse reddit while sitting on a machine. Make
          sure to use headphones so you can ignore anyone asking you how long you're going to be.
        </li>
        <li>
          Bring a phone stand and video record your exercises. For best results, check the video after every rep to
          ensure that your butt looks great.
        </li>
        <li>
          Use a lot of different weights. For best results, continue adding weight by the smallest increment possible
          and make sure that you change a lot of weights between sets.
        </li>
        <li>
          When getting weights, shoot for the farthest location possible from where you are located. Bonus points for
          dragging the 20kg barbell across the weight room to use it for bicep curls.
        </li>
        <li>
          Bring a few friends and hang out at the squat racks. You only need one person to actually do any exercises,
          the others can just hang around and/or flump around with the bar.
        </li>
      </ul>

      <figure className="figure blog">
        <a href={blogImage}>
          <img src={blogImage} className="img-responsive bordered p-centered" alt={blogImageText} />
        </a>
        <figcaption className="figure-caption text-center">{blogImageText}</figcaption>
      </figure>

      <ul className="text">
        <li>Stay away from super-sets and circuit training. Sweating is for losers.</li>
        <li>Never bring a bottle, take your time walking over to the water fountain and wait in line to drink.</li>
        <li>Never plan your workout, just wait for the machine/weights that are in highest demand.</li>
        <li>Pro bonus: hang out in the locker room so that you can boast about how much time you spent at the gym.</li>
      </ul>

      <p>
        Needless to say, we've all been there and done that :). If you're looking for a easy solution to log your
        workouts and track your progress, try <Link to="/log-workout">ewolo</Link> for free!
      </p>
    </BlogTemplate>
  );
};

const HowToWasteTimeAtTheGymLikeAProEntry = {
  ...entry,
  component: BlogEntry,
};

export default HowToWasteTimeAtTheGymLikeAProEntry;
