import reduxLogger from "redux-logger";
import { applyMiddleware, createStore, compose } from "redux";
import { routerMiddleware } from "connected-react-router";

import thunk from "./middleware/thunk";
import analytics from "./middleware/analytics";
import authenticatedRoutesCheck from "./middleware/authenticated-routes-check";
import userDataReduxMiddleware from "./middleware/userDataReduxMiddleware";
import notificationMiddleware from "./middleware/notificationMiddleware";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createEwoloStore = (history, appReducer, extraMiddleWare) => {
  const storeMiddlware = [
    ...extraMiddleWare,
    routerMiddleware(history),
    thunk,
    authenticatedRoutesCheck,
    userDataReduxMiddleware,
    notificationMiddleware,
  ];

  // only enable state logging in debug
  if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test") {
    storeMiddlware.push(reduxLogger);
  }

  // only enable analytics in production
  if (process.env.NODE_ENV === "production") {
    storeMiddlware.push(analytics);
  }

  const store = createStore(appReducer(history), composeEnhancers(applyMiddleware(...storeMiddlware)));

  return store;
};

export default createEwoloStore;
