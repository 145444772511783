import React, { Component } from "react";
import { Prompt } from "react-router";

export default function withFormNavigationPrompt(WrappedComponent) {
  return class RouteFormNavigationPrompt extends Component {
    constructor(props) {
      super(props);

      this.state = {
        formUpdated: false,
      };
    }

    formUpdated = () => {
      if (!this.state.formUpdated) {
        const newState = {
          ...this.state,
          formUpdated: true,
        };
        this.setState(newState);
      }
    };

    formSaved = () => {
      const newState = {
        ...this.state,
        formUpdated: false,
      };
      this.setState(newState);
    };

    render() {
      return (
        <div>
          <Prompt
            when={this.state.formUpdated}
            message="You have unsaved changes. Are you sure you want to navigate away from this page?"
          />
          <WrappedComponent formUpdated={this.formUpdated} formSaved={this.formSaved} {...this.props} />
        </div>
      );
    }
  };
}
