import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import fileDownload from "js-file-download";

import UserNotificationBar from "../notification/UserNotificationBar";
import EwoloFormHint from "../generic/EwoloFormHint";

import { ewoloUtil } from "ewolo-core-js";
import accountActions from "../../modules/account/accountActions";

const mapStateToProps = (state) => {
  return { account: state.account, userData: state.user.data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAccountSetPasswordData: (oldPassword, password) => {
      dispatch(accountActions.accountSetPasswordData(oldPassword, password));
    },
    doAccountPasswordUpdateThunk: () => {
      dispatch(accountActions.accountPasswordUpdateThunk());
    },
    doAccountSetData: ({ name, units, sex }) => {
      dispatch(accountActions.accountSetData({ name, units, sex }));
    },
    doAccountDataUpdateThunk: () => {
      dispatch(accountActions.accountDataUpdateThunk());
    },
  };
};

class Account extends Component {
  state = {
    name: null,
    units: null,
    sex: null,
    isLoadingExport: false,
  };

  componentDidMount() {
    const newState = {
      name: this.props.userData.name,
      units: this.props.userData.units,
      sex: this.props.userData.sex,
    };

    this.props.doAccountSetData(newState);
    this.setState(newState);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // in the case that the account page is loaded directly we need this check to set the default values ...
    if (
      prevState.name !== nextProps.userData.name ||
      prevState.units !== nextProps.userData.units ||
      prevState.sex !== nextProps.userData.sex
    ) {
      const newState = {
        name: nextProps.userData.name,
        units: nextProps.userData.units,
        sex: nextProps.userData.sex,
      };
      nextProps.doAccountSetData(newState);

      return newState;
    }

    // Return null to indicate no change to state.
    return null;
  }

  handleBtnUpdatePasswordClick = (event) => {
    event.preventDefault();
    this.props.doAccountPasswordUpdateThunk();
  };

  handleBtnExportDataClick = (event) => {
    event.preventDefault();
    this.setState({
      isLoadingExport: true,
    });

    const promise = ewoloUtil.getApiRequest({
      route: "/export",
      method: "GET",
      authToken: this.props.userData.authToken,
    });

    return promise
      .then(ewoloUtil.getApiResponse)
      .then((data) => {
        fileDownload(JSON.stringify(data, null, 2), `ewolo-export-${moment().unix()}.json`, "application/json");
      })
      .catch((error) => {
        alert(error);
      })
      .then(() => {
        this.setState({
          isLoadingExport: false,
        });
      });
  };

  handleOldPasswordChange = (event) => {
    this.props.doAccountSetPasswordData(event.target.value, this.props.account.password);
  };

  handlePasswordChange = (event) => {
    this.props.doAccountSetPasswordData(this.props.account.oldPassword, event.target.value);
  };

  handleNameChange = (event) => {
    this.props.doAccountSetData({
      name: event.target.value,
      units: this.props.account.units,
      sex: this.props.account.sex,
    });
  };

  handleUnitSelectionChange = (event) => {
    this.props.doAccountSetData({
      name: this.props.account.name,
      units: event.target.value,
      sex: this.props.account.sex,
    });
  };

  handleSexChange = (event) => {
    const sex = ewoloUtil.textToSex(event.target.name);

    this.props.doAccountSetData({
      name: this.props.account.name,
      units: this.props.account.units,
      sex: sex,
    });
  };

  handleBtnUpdateAccountClick = (event) => {
    event.preventDefault();
    this.props.doAccountDataUpdateThunk();
  };

  render() {
    return (
      <div>
        <UserNotificationBar />
        <div className="container grid-xs section-content">
          <div className="columns">
            <div className="column col-12">
              <div>
                <h3>Account Details</h3>
                <form className="form-horizontal">
                  <div className="form-group">
                    <div className="col-4">
                      <label className="form-label">Full name</label>
                    </div>
                    <div className="col-8">
                      <input
                        className="form-input"
                        type="text"
                        placeholder="Full name"
                        value={this.props.account.name}
                        onChange={this.handleNameChange}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-4">
                      <label className="form-label">Default weight</label>
                    </div>
                    <div className="col-8">
                      <select
                        className="form-select"
                        value={this.props.account.units}
                        onChange={this.handleUnitSelectionChange}
                      >
                        <option value="1">Pounds (lbs)</option>
                        <option value="2">Kilograms (kgs)</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-4">
                      <label className="form-label">Sex</label>
                    </div>
                    <div className="col-8">
                      <label className="form-radio">
                        <input
                          type="radio"
                          name="male"
                          checked={this.props.account.sex === 2}
                          onChange={this.handleSexChange}
                        />
                        <i className="form-icon" />
                        Male
                      </label>
                      <label className="form-radio">
                        <input
                          type="radio"
                          name="female"
                          checked={this.props.account.sex === 3}
                          onChange={this.handleSexChange}
                        />
                        <i className="form-icon" />
                        Female
                      </label>
                      <label className="form-radio">
                        <input
                          type="radio"
                          name="other"
                          checked={this.props.account.sex === 1}
                          onChange={this.handleSexChange}
                        />
                        <i className="form-icon" />
                        Other
                      </label>
                    </div>
                  </div>

                  <div className="form-group margin-top-1rem">
                    <div className="col-12 text-center">
                      <button className="btn btn-primary btn-lg" onClick={this.handleBtnUpdateAccountClick}>
                        Update settings
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="column col-12 margin-top-1rem">
              <div>
                <h3>Password</h3>
                <form className="form-horizontal">
                  <div className="form-group">
                    <div className="col-12">
                      <input
                        className="form-input"
                        type="password"
                        placeholder="Current password"
                        value={this.props.account.oldPassword}
                        onChange={this.handleOldPasswordChange}
                      />
                    </div>
                  </div>

                  <EwoloFormHint formHint={this.props.account.oldPasswordFormHint} />

                  <div className="form-group">
                    <div className="col-12">
                      <input
                        className="form-input"
                        type="password"
                        placeholder="New password"
                        value={this.props.account.password}
                        onChange={this.handlePasswordChange}
                      />
                    </div>
                  </div>

                  <EwoloFormHint formHint={this.props.account.passwordFormHint} />

                  <div className="form-group margin-top-1rem">
                    <div className="col-12 text-center">
                      <button
                        className={
                          "btn btn-primary btn-lg " +
                          (this.props.account.oldPassordFormHint ||
                          this.props.account.passwordFormHint ||
                          !this.props.account.oldPassword ||
                          !this.props.account.password
                            ? "disabled"
                            : "")
                        }
                        onClick={this.handleBtnUpdatePasswordClick}
                      >
                        Update password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="column col-12 margin-top-1rem">
              <div>
                <h3>Export</h3>
                <form className="form-horizontal">
                  <div className="form-group">
                    <div className="col-12 text-center">
                      <button
                        className={"btn btn-primary btn-lg " + (this.state.isLoadingExport ? "loading disabled" : "")}
                        onClick={this.handleBtnExportDataClick}
                      >
                        Export Data
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
