import Noty from "noty";
import moment from "moment";

import { c as globalActionNames } from "../../modules/global/globalActions";

const notificationMiddleware = (store) => (next) => (action) => {
  if (action.type === globalActionNames.USER_NOTIFICATION_ADD) {
    const notyOptions = {
      theme: "spectre",
      // theme: "bulma",
      type: action.userNotificationType.toLowerCase(),
      closeWith: ["click"],
      layout: "centerRight",
      timeout: 3500, // false,
      text: moment(action.at).format("HH:mm:ss") + " " + action.userNotificationText,
      // text: JSON.stringify(action),
    };

    new Noty(notyOptions).show();

    return;
  }

  return next(action);
};

export default notificationMiddleware;
