import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import userReducer from "./userReducer";
import globalReducer from "./global/globalReducer";
import signupReducer from "./signup/signupReducer";
import loginReducer from "./login/loginReducer";
import accountReducer from "./account/accountReducer";
import publikReducer from "./publik/publikReducer";

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    signup: signupReducer,
    login: loginReducer,
    global: globalReducer,
    account: accountReducer,
    publik: publikReducer,
  });

export default appReducer;
