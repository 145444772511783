import React, { Component } from "react";
import { connect } from "react-redux";

import logWorkoutActions from "../../modules/log-workout/logWorkoutActions";

import LogWorkout from "./LogWorkout";

import { ewoloUtil } from "ewolo-core-js";

const mapStateToProps = (state) => {
  return {
    logWorkout: state.user.logWorkout,
    defaultUnits: state.user.data.units,
    userId: state.user.data.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doLogWorkout: () => {
      dispatch(logWorkoutActions.logWorkout());
    },
    doLogWorkoutExercise: ({ name, exerciseNotes, reps, weight, sets, tempo, rest, showAdvanced, units }) => {
      dispatch(
        logWorkoutActions.logWorkoutExercise({
          name,
          exerciseNotes,
          reps,
          weight,
          sets,
          tempo,
          rest,
          showAdvanced,
          units,
        })
      );
    },
  };
};

/**
 * Note that there is a small bug here where we do not have a `nameFormHint` for the initial page render which then
 * allows the Save button to be enabled even though, there is no exercise provided. Earlier this issue was mitigated
 * with the default Squats option but we removed it as part of a feature :(.
 *
 * To somehow mitigate the above issue, we set a default exercise name when there is no user logged in. It will
 * hopefully eliminate some basic errors!
 */
class LogWorkoutNew extends Component {
  componentDidMount() {
    if (!this.props.logWorkout.componentMounted) {
      // Well we want to keep the form the way it was.
      this.props.doLogWorkout();
      this.props.doLogWorkoutExercise({ name: this.props.userId ? "" : "Squats", units: this.props.defaultUnits });
    }
  }

  render() {
    if (!this.props.logWorkout.componentMounted) {
      return <div />;
    }

    return (
      <LogWorkout
        formSaved={this.props.formSaved ? this.props.formSaved : ewoloUtil.noOp}
        formUpdated={this.props.formUpdated ? this.props.formUpdated : ewoloUtil.noOp}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogWorkoutNew);
