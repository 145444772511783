import { ewoloUtil, ewoloConstants } from "ewolo-core-js";

import { c as userDataActionNames } from "../../modules/user-data/userDataActions";

const userDataReduxMiddleware = (store) => (next) => (action) => {
  if (action.type === userDataActionNames.USER_DATA_SET) {
    const { name, email, sex, units, userExerciseNames } = action;

    const userData = {
      name,
      email,
      sex,
      units,
      userExerciseNames,
    };

    ewoloUtil.storeObject(ewoloConstants.storage.userDataKey, userData);
  }

  return next(action);
};

export default userDataReduxMiddleware;
